// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Icon, Label, Feed,
} from 'semantic-ui-react';

import './HistoryMenuItem.less';

import * as i18n from '../../../config/i18n';

import { ApplicationWindow } from '../../../components/ApplicationWindow';
import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

import { HistoryEtat } from '../History';

import {
  getApplicationClassName, getClassName,
} from '../../../components/utils/className';

const className = getApplicationClassName('history', 'item');

const etatIcon = {
  creation: 'add',
  modification: 'pencil',
  suppression: 'trash',
};

const etatColor = {
  creation: 'green',
  modification: 'orange',
  suppression: 'red',
};

const etatType = {
  creation: 'creation',
  modification: 'modification',
  suppression: 'suppression',
};

export const HistoryMenuItem = (props) => {
  const {
    adresse,
    titre,
    description,
    etat,
    date,
    type,
    utilisateur,
  } = props;

  const HistoryIconOpen = React.useMemo(() => (
    <Icon name="share square" link />
  ), []);

  const OpenAction = React.useMemo(() => (
    {
      key: 'open',
      icon: HistoryIconOpen,
      title: i18n.navigationOpenTab,
    }
  ), []);

  const content = React.useMemo(() => (
    <div className={getClassName(className, {
      creation: etat === HistoryEtat.creation,
      modification: etat === HistoryEtat.modification,
      suppression: etat === HistoryEtat.suppression,
    })}
    >
      <Icon
        name={etatIcon[etat]}
        color={etatColor[etat]}
        size="small"
      />
      <span>{titre}</span>
    </div>
  ), [titre, etat]);

  const title = React.useMemo(() => (
    <Feed className="history">
      <Feed.Event>
        <Feed.Content>
          <Label ribbon="right" color={etatColor[etat]}>
            {`${etatType[etat]} de ${type}`}
          </Label>
          <Feed.Date>
            {date}
          </Feed.Date>
          <Feed.Summary>
            {titre}
          </Feed.Summary>
        </Feed.Content>
        <Feed.Extra>
          {description}
        </Feed.Extra>
        <Feed.Meta>
          <Icon name="user" color="grey" size="tiny" circular inverted />
          {utilisateur?.nom}
          {' '}
          {utilisateur?.prenom}
        </Feed.Meta>
      </Feed.Event>
    </Feed>
  ), [titre, etat, date, type, description, utilisateur]);

  return (
    <ApplicationWindow path={adresse}>
      {({ onOpen }) => (
        <ApplicationMenuItem
          content={content}
          title={title}
          actions={[
            etat !== HistoryEtat.suppression && { ...OpenAction, onClick: onOpen },
          ].filter(Boolean)}
          path={etat !== HistoryEtat.suppression && adresse}
          offset={2}
          direction="row"
          popup
          link={etat !== HistoryEtat.suppression}
        />
      )}
    </ApplicationWindow>
  );
};
