// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationListItemAction } from '../../../components/ApplicationList/ApplicationListItemAction';

import {
  IconClose, IconDone, IconExpand, IconReduce,
} from './NotificationActionIcon';

export const CloseAction = (props) => {
  const { onClick } = props;

  return (
    <ApplicationListItemAction
      icon={<IconClose />}
      title={i18n.notificationClose}
      titlePosition="left center"
      onClick={onClick}
    />
  );
};

export const DoneAction = (props) => {
  const { onClick } = props;

  return (
    <ApplicationListItemAction
      icon={<IconDone />}
      title={i18n.notificationDone}
      titlePosition="left center"
      onClick={onClick}
    />
  );
};

export const ExpandAction = (props) => {
  const {
    expand,
    onClick,
  } = props;

  return (
    <ApplicationListItemAction
      icon={expand ? <IconReduce /> : <IconExpand />}
      title={expand ? i18n.notificationReduce : i18n.notificationExpand}
      onClick={onClick}
    />
  );
};
