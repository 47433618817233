// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import { Reducer } from 'redux';

import { modules } from '../data';

import { ModulesType, ModuleType } from './types';
import { UserLogoutType, UserProfileType } from '../../User/redux/types';

import {
  reduceAll, reduceOne,
} from '../../libs/redux/reducer';

const sort = ['domaine.identifiant', 'ordre'];

const initialState = _.sortBy(
  modules,
  sort,
);

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const modulesReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ModulesType:
      return reduceAll(state, action.modules, sort);

    case ModuleType:
      return reduceOne(
        state,
        action.module,
        (module) => module.identifiant !== action.identifiant,
        sort,
      );

    case UserLogoutType:
    case UserProfileType:
      return initialState;

    default:
      return state;
  }
};
