// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import {
  Portal, Modal, Button, Segment,
} from 'semantic-ui-react';

export const DashboardBarConfirm = (props) => {
  const {
    children,
    header,
    content,
    container,
    open,
    onConfirm,
    onCancel,
  } = props;

  const handleCancel = React.useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleConfirm = React.useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const actions = React.useMemo(() => (
    children || (
    <>
      <Button basic size="small" icon="cancel" content="Annuler" onClick={handleCancel} />
      <Button primary icon="check" content="Confirmer" onClick={handleConfirm} />
    </>
    )
  ), [
    children,
    handleCancel,
    handleConfirm,
  ]);

  const modal = React.useMemo(() => (
    <Modal
      mountNode={container}
      open={open}
      size="small"
      closeOnDimmerClick
      closeOnDocumentClick
      closeOnEscape
      onClose={handleCancel}
    >
      <Modal.Header as={Segment} inverted>{header}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        {actions}
      </Modal.Actions>
    </Modal>
  ), [
    container,
    open,
    header,
    content,
    actions,
    handleCancel,
  ]);

  return (
    <Portal
      open={open}
      transition={{ animation: 'scale' }}
      closeOnDocumentClick={false}
      closeOnTriggerClick={false}
      closeOnTriggerBlur={false}
      mountNode={container}
    >
      {modal}
    </Portal>
  );
};

export default DashboardBarConfirm;
