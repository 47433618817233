// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { toast } from 'react-toastify';

import {
  Label, Loader, Transition,
} from 'semantic-ui-react';

// import { useSubscribeNotifications } from '../services';

export const NotificationCount = (props) => {
  const {
    visible,
    count,
    loading,
  } = props;

  // const subscribeNotifications = useSubscribeNotifications();

  // React.useEffect(() => {
  //   const unsubscriber = subscribeNotifications();

  //   return () => {
  //     //unsubscriber();
  //   };
  // }, []);

  React.useEffect(() => {
    if (count) {
      toast.info(count === 1
        ? 'Vous avez une notification non lue.'
        : `Vous avez ${count} notifications non lues.`, { toastId: 'APP_NOTIFICATION_RECEIVED' });
    }
  }, [count]);

  const label = React.useMemo(() => (
    <Label color="yellow" floating size="tiny" circular aligned>
      {
        // eslint-disable-next-line no-nested-ternary
        loading
          ? <Loader active inline elastic size="mini" />
          : (count > 9 ? '9+' : count)
      }
    </Label>
  ), [
    loading,
    count,
  ]);

  return React.useMemo(() => (
    <Transition visible={!!count && visible} animation="fade">
      {label}
    </Transition>
  ), [
    count,
    label,
    visible,
  ]);
};
