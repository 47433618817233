// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { DraftLauncherMenuItem } from './DraftLauncherMenuItem';
import { DraftLauncherMenuList } from './DraftLauncherMenuList';

import { DraftDataProvider } from './DraftDataProvider';

export const DraftLauncherMenu = (props) => {
  // eslint-disable-next-line react/prop-types
  const { domain } = props;

  const getDraftsItems = React.useCallback((brouillons) => _.map(
    brouillons,
    ({
      identifiant, titre, description, date, adresse,
    }) => (
      <DraftLauncherMenuItem
        key={identifiant}
        identifiant={identifiant}
        adresse={adresse}
        titre={titre}
        date={date}
        description={description}
      />
    ),
  ), []);

  const renderDraftsProvider = React.useCallback((brouillons) => (
    <DraftLauncherMenuList items={getDraftsItems(brouillons)} />
  ), [getDraftsItems]);

  return (
    <DraftDataProvider domain={domain}>
      {renderDraftsProvider}
    </DraftDataProvider>
  );
};
