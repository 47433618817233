// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { Reducer } from 'redux';

import { DraftsType, DraftType } from './types';
import { UserLogoutType, UserProfileType } from '../../User/redux/types';

import {
  reduceAll, reduceOne,
} from '../../libs/redux/reducer';

const sort = ['module.identifiant', 'date'];

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const draftsReducer: Reducer = (state = [], action) => {
  switch (action.type) {
    case DraftsType:
      return reduceAll(state, action.drafts, sort);

    case DraftType:
      return reduceOne(
        state,
        action.draft,
        (draft) => draft.identifiant !== action.identifiant,
        sort,
      );

    case UserLogoutType:
    case UserProfileType:
      return [];

    default:
      return state;
  }
};
