// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { usePrefetch } from '../../libs/hooks/usePrefetch';

import { DomainService } from '../services';

export const DomainDataPrefetch = (props) => {
  const {
    children,
  } = props;

  const prefetchDomains = usePrefetch('domains', DomainService.getDomains);

  const handleHover = React.useCallback(() => {
    prefetchDomains();
  }, []);

  return children && React.cloneElement(children, {
    onMouseEnter: handleHover,
  });
};
