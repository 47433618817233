// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import type { Dashboard } from '../Dashboard';

import { useCurrent } from './useCurrent';

export const useIndex = (dashboards: Dashboard[]):
[Dashboard, [number, number], [() => void, () => void]] => {
  const [current, setCurrent] = useCurrent();

  const incrementIndex = React.useCallback(() => {
    setCurrent(Math.max(_.findIndex(dashboards, current) - 1, 0));
  }, [
    dashboards,
    current,
  ]);

  const decrementIndex = React.useCallback(() => {
    setCurrent(Math.min(_.findIndex(dashboards, current) + 1, _.size(dashboards)));
  }, [
    dashboards,
    current,
  ]);

  return [
    current,
    [_.findIndex(dashboards, current) + 1, _.size(dashboards)],
    [incrementIndex, decrementIndex],
  ];
};

export default useIndex;
