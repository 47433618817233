// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Notification } from '../Notification';

import { NotificationsType, NotificationType } from './types';

export const addNotificationsAction = (notifications: Notification[]) => ({
  type: NotificationsType,
  notifications,
});

export const addNotificationAction = (notification: Notification) => ({
  type: NotificationType,
  notification,
  identifiant: notification.identifiant,
});

export const updateNotificationAction = (notification: Notification) => ({
  type: NotificationType,
  notification,
  identifiant: notification.identifiant,
});

export const deleteNotificationAction = (identifiant: string | number) => ({
  type: NotificationType,
  identifiant,
});

export const deleteNotificationsAction = () => ({
  type: NotificationsType,
});
