// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import './UserAvatar.less';

import { ApplicationSessionContext } from '../../../components/ApplicationSession';

import {
  getApplicationClassName,
} from '../../../components/utils/className';

const className = getApplicationClassName('user', 'avatar');

export const UserAvatar = () => {
  const { user } = React.useContext(ApplicationSessionContext);

  return React.useMemo(() => (
    <span className={className} aria-label={`${user?.prenom} ${user?.nom}`}>
      <span>{`${user?.prenom[0]?.toUpperCase()}${user?.nom[0]?.toUpperCase()}`}</span>
    </span>
  ), [user]);
};
