// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import DashboardBarButton from './DashboardBarButton';

export const DashboardBarButtonSuivant = (props) => {
    const { 
        disabled,
        onClick,
    } = props;

    const handleClick = React.useCallback(() => {
        onClick?.()
    }, [onClick]);

    return (
        <DashboardBarButton
            icon='arrow right'
            label='Suivant'
            disabled={disabled}
            onClick={handleClick}
        />
    );
}

export default DashboardBarButtonSuivant;
