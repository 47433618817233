// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import { Grid, Popup, Image } from 'semantic-ui-react';

import DashboardBarButton from './DashboardBarButton';

export const DashboardBarButtonAjouter = (props) => {
    const { onClick } = props;

    const [open, setOpen] = React.useState(false);

    const handleClick = React.useCallback((type) => () => {
        onClick?.({
            nom: `Tableau de bord`,
            type,
        })

        setOpen(false)
    }, [onClick])

    const handleOpen = React.useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = React.useCallback(() => {
        setOpen(false)
    }, [])

    const trigger = React.useMemo(() => (
        <DashboardBarButton
            icon='add'
            label='Ajouter'
        />
    ), []);

    const content = React.useMemo(() => (
        <Grid className="dashboard-bar-add" columns={3}>
            {(new Array(3)).fill(0).map((r, ri) => (
                <Grid.Row key={`row-${ri}`}>
                    {(new Array(3)).fill(0).map((c, ci) => (
                        <Grid.Column key={`col-${ci}`}>
                            <Image
                                as='a'
                                className={`struct${(ri * 3) + (ci + 1)}`}
                                size='tiny'
                                onClick={handleClick((ri * 3) + (ci + 1))}
                            />
                        </Grid.Column>
                    ))}
                </Grid.Row>
            ))}
        </Grid>
    ), []);

    return (
        <Popup
            position="bottom left"
            trigger={trigger}
            content={content}
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            on='click'
            flowing
        />
    );
}

export default DashboardBarButtonAjouter;
