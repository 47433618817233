// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Domain } from '../Domain';

import { DomainsType, DomainType } from './types';

export const addDomainsAction = (domains: Domain[]) => ({
  type: DomainsType,
  domains,
});

export const addDomainAction = (domain: Domain) => ({
  type: DomainType,
  domain,
  identifiant: domain.identifiant,
});
