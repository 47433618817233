// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { Domain } from '../Domain';

export const domains: Domain[] = [
  {
    identifiant: 0,
    ordre: 1,
    libelle: 'Accueil',
    icone: 'home',
    code: 'accueil',
    adresse: '/',
  },
];
