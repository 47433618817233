// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationMenuAction } from '../../../components/ApplicationMenu/ApplicationMenuAction';
import { ApplicationMenuActionItem } from '../../../components/ApplicationMenu/ApplicationMenuActionItem';

import { SpeedDialDataProvider } from './SpeedDialDataProvider';

export const SpeedDialMenu = (props) => {
  const {
    module,
  } = props;

  const getSpeedDial = React.useCallback((buttons) => _.map(
    buttons,
    ({
      identifiant, titre, icone, adresse, onClick,
    }) => (
      <ApplicationMenuActionItem
        key={identifiant}
        identifiant={identifiant}
        title={titre}
        icon={icone}
        path={adresse}
        onClick={onClick}
      />
    ),
  ), []);

  const renderSpeedDialProvider = React.useCallback((speedial) => (
    <ApplicationMenuAction
      key="speedial"
      actions={getSpeedDial(speedial?.buttons)}
    />
  ), [getSpeedDial]);

  return React.useMemo(() => (
    <SpeedDialDataProvider module={module}>
      {renderSpeedDialProvider}
    </SpeedDialDataProvider>
  ), [
    module,
    renderSpeedDialProvider,
  ]);
};
