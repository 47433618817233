// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { Loader, Segment, Header, Icon } from 'semantic-ui-react';

import useCurrent from '../hooks/useCurrent';

import DashboardGrid from './DashboardGrid';
import DashboardHeader from './DashboardHeader';

export const Dashboard = (props) => {
    const [dashboard,, loading] = useCurrent();

    const header = React.useMemo(() => (
        <DashboardHeader content={dashboard?.nom} />
    ), [dashboard?.nom])

    const grid = React.useMemo(() => (
        <DashboardGrid dashboard={dashboard?.identifiant} type={dashboard?.type} widgets={dashboard?.widgets} />
    ), [
        dashboard?.identifiant,
        dashboard?.type,
        dashboard?.widgets,
    ]);
    
    return (        
        <div className="dashboard">
            {loading 
                ? <Loader active /> 
                : dashboard ? (
                    <>
                        {header}
                        {grid}
                    </>
                ) : (
                    <Segment placeholder style={{ height: "100%" }}>
                        <Header icon>
                            <Icon name='dashboard' />
                            Vous n'avez aucun tableau de bord
                        </Header>
                    </Segment>
                )
            }
        </div>
    )
}

export default Dashboard;
