// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import {
  Menu, Popup, Button, Icon,
} from 'semantic-ui-react';

const DashboardBarButton = (props) => {
  const {
    children,
    icon,
    label,
    labelPosition,
    disabled,
    loading,
    onClick,
    ...rest
  } = props;

  const trigger = React.useMemo(() => (
    <Menu.Item
      as={Button}
      icon
      labelPosition={labelPosition}
      disabled={disabled || loading}
      loading={loading}
      onClick={onClick}
      {...rest}
    >
      <Icon name={icon} />
      {label}
    </Menu.Item>
  ), [
    icon,
    disabled,
    loading,
    rest,
    children,
    onClick,
  ]);

  return trigger;
};

export default DashboardBarButton;
