// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationListItemAction } from '../../../components/ApplicationList/ApplicationListItemAction';

import {
  IconDelete, IconOpen, IconReorder,
} from './FavoriteActionIcon';

import { FavoriteService } from '../services';

export const OpenAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;

  return (
    <ApplicationListItemAction
      icon={<IconOpen />}
      title={i18n.navigationOpenTab}
      onClick={onClick}
    />
  );
};

export const DeleteAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { identifiant } = props;

  const [loading, setLoading] = React.useState(false);

  const handleDeleteFavoriteClick = React.useCallback(() => {
    setLoading(true);

    FavoriteService.deleteFavorite(identifiant)
      .finally(() => { setLoading(false); });
  }, [identifiant]);

  return (
    <ApplicationListItemAction
      icon={<IconDelete />}
      title={i18n.favoriteDelete}
      loading={loading}
      onClick={handleDeleteFavoriteClick}
    />
  );
};

export const ReorderAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onMouseDown } = props;

  return (
    <ApplicationListItemAction
      icon={<IconReorder />}
      title={i18n.favoriteReorder}
      onMouseDown={onMouseDown}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ cursor: 'grab' }}
    />
  );
};
