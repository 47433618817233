// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationWindow } from '../../../components/ApplicationWindow';
import { ApplicationLauncherMenuItem } from '../../../components/ApplicationLauncher';

import { OpenAction } from './ModuleAction';

import { FeatureDataPrefetch } from '../../Feature/components/FeatureDataPrefetch';

export const ModuleLauncherMenuItem = (props) => {
  const {
    adresse,
    libelle,
    module,
    ...rest
  } = props;

  return (
    <ApplicationWindow path={adresse}>
      {({ onOpen }) => (
        <FeatureDataPrefetch path={adresse}>
          <ApplicationLauncherMenuItem
            name={`module.${_.snakeCase(libelle)}`}
            content={libelle}
            actions={[
              <OpenAction key="open" onClick={onOpen} />,
            ]}
            path={adresse}
            offset={module ? 1 : 0}
            link
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        </FeatureDataPrefetch>
      )}
    </ApplicationWindow>
  );
};
