// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import React from 'react';

import { Icon } from 'semantic-ui-react';

export const AlertType = () => (
  <Icon
    name="bullhorn"
    color="orange"
  />
);

export const TaskType = () => (
  <Icon
    name="tasks"
    color="grey"
  />
);
