// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import React from 'react';

import { Icon } from 'semantic-ui-react';

export const HighPriority = () => (
  <Icon
    name="long arrow alternate up"
    color="red"
  />
);

export const LowPriority = () => (
  <Icon
    name="long arrow alternate down"
    color="blue"
  />
);

export const NormalPriority = () => (
  <Icon
    name="arrows alternate vertical"
    color="black"
  />
);
