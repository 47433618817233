// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Module } from '../Module';

import { ModulesType, ModuleType } from './types';

export const addModulesAction = (modules: Module[]) => ({
  type: ModulesType,
  modules,
});

export const addModuleAction = (module: Module) => ({
  type: ModuleType,
  module,
  identifiant: module.identifiant,
});
