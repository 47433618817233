// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import type { Feature } from '../Feature';
import type { User } from '../../User';

import * as redux from '../redux';

import { Service } from '../../libs/services/Service';

export class FeatureService extends Service {
  static async addFeatureByManifest(
    manifest: { identifiant: string | number, features: { id: string } },
    user: User,
  ): Promise<Feature[]> {
    try {
      let result = [];

      const { identifiant, features } = manifest;

      if (features) {
        result = _.map(
          _.filter(
            features,
            (feature) => !feature.onChanged || feature.onChanged(user),
          ),
          (feature) => ({
            identifiant: feature.id,
            libelle: feature.name,
            adresse: feature.path,
            components: feature.components,
            module: { identifiant },
          }),
        ) as Feature[];

        FeatureService.dispatch(redux.addFeaturesAction(result));
      }

      return result;
    } catch (e) {
      return [];
    }
  }

  static queryFeatureByPage(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _disabled?: boolean,
  ): [Feature, boolean, boolean] {
    const feature = FeatureService.select<{ features: Feature[] }, Feature>(
      redux.featureByPageSelector(path),
    );

    return [
      feature,
      false,
      false,
    ];
  }

  static queryFeaturesByQuery(
    query: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _disabled?: boolean,
  ): [Feature[], boolean, boolean] {
    const features = FeatureService.select<{ features: Feature[] }, Feature[]>(
      redux.featuresByQuerySelector(query),
    );

    return [
      features,
      false,
      false,
    ];
  }
}
