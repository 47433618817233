// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { useMemo } from '@jvs-group/jvs-mairistem-tools';

export const DataProviderSuspender = (props) => {
  const {
    children,
    data,
    loading,
    fetching,
  } = props;

  if (loading) {
    throw new Promise((resolve) => resolve(data));
  }

  const component = useMemo(() => (
    _.isFunction(children)
      ? children(data)
      : children
  ), [
    children,
    data,
  ]);

  return children && React.cloneElement(component, { loading, fetching });
};
