// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { History } from '../History';

import { HistoryType } from './types';

export const addHistoryAction = (history: History[]) => ({
  type: HistoryType,
  history,
});
