// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

export const reduceAll = (state, data, sort = []) => _.sortBy(
  _.uniqBy(
    _.compact(
      [
        ...data,
        ...state,
      ],
    ),
    'identifiant',
  ),
  sort,
);

export const reduceOne = (state, data, callback, sort = []) => _.sortBy(
  _.compact(
    [
      ..._.filter(
        state,
        callback,
      ),
      data,
    ],
  ),
  sort,
);
