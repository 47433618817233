// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Icon } from 'semantic-ui-react';

export const IconClose = () => (
  <Icon name="close" link />
);

export const IconDone = () => (
  <Icon name="check circle" link />
);

export const IconExpand = () => (
  <Icon name="angle down" link />
);

export const IconReduce = () => (
  <Icon name="angle up" link />
);
