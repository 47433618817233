// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { Reducer } from 'redux';

import { MenuType } from './types';
import { UserLogoutType /* , UserProfileType */ } from '../../User/redux/types';

import { reduceAll } from '../../libs/redux/reducer';

const sort = ['module.identifiant', 'identifiantParent', 'ordre'];

// eslint-disable-next-line default-param-last, @typescript-eslint/default-param-last
export const menuReducer: Reducer = (state = [], action) => {
  switch (action.type) {
    case MenuType:
      return reduceAll(state, action.menu, sort);

    case UserLogoutType:
    // case UserProfileType:
      return [];

    default:
      return state;
  }
};
