// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { useMemo } from '@jvs-group/jvs-mairistem-tools';

import { DataProviderSuspender } from './DataProviderSuspender';

export const DataProvider = (props) => {
  const {
    children,
    data,
    loading,
    fetching,
  } = props;

  const component = React.useMemo(() => children && React.cloneElement(
    _.isFunction(children)
      ? children()
      : children,
    { loading: true, fetching: true },
  ),
  [children]);
  
  return useMemo(
    () => children && (
      <React.Suspense fallback={component}>
        <DataProviderSuspender
          data={data}
          loading={loading}
          fetching={fetching}
        >
          {children}
        </DataProviderSuspender>
      </React.Suspense>
    ),
    [
      data,
      children,
      loading,
      fetching,
    ],
  );
};
