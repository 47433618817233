// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Modal, Icon, Button,
} from 'semantic-ui-react';

import * as api from '../../../../config/api';
import * as i18n from '../../../../config/i18n';

import { ApplicationSessionContext } from '../../../../components/ApplicationSession';

import { ComponentList } from './ComponentList';

import { privateApi } from '../../../../api';

import { UserService } from '../../services';
import { Tenant } from '../../Tenant';

export const ComponentSelector = (props) => {
  const {
    show,
    cancellable = true,
    onHide,
    onSelect,
  } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const [items, setItems] = React.useState(user?.tenant?.components);
  const [selected, setSelected] = React.useState(_.find(items, { current: true }));
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (user && selected) {
      setLoading(true);

      UserService.component(user, _.find(items, (i) => i.identifiant === selected.identifiant))
        .then((component) => {
          UserService.profile(user, user.tenant, component)
            .then(() => {
              setLoading(false);

              onSelect?.(selected);
              onHide?.();
            })
            .catch(() => { setLoading(false); });
        })
        .catch(() => { setLoading(false); });
    }
  }, [
    items,
    user,
    selected,
    onSelect,
    onHide,
  ]);

  const handleSelect = React.useCallback((value) => {
    setSelected(value);
  }, []);

  const handleClose = React.useCallback(() => {
    onHide?.();
  }, [onHide]);

  React.useEffect(() => {
    if (user && show) {
      React.startTransition(() => {
        privateApi.get(api.compilePath(api.tenantEndPoint, { tenant: user?.tenant.identifiant }))
          .then((results: Tenant) => {
            if (_.size(results) === 1) {
              const current = _.first(results.components);

              UserService.component(user, current)
                .then((component) => {
                  UserService.profile(user, user.tenant, component);
                });
            } else {
              setItems(results.components);
              setOpen(true);
            }
          });
      });
    } else {
      setOpen(false);
    }
  }, [user?.tenant.identifiant, show]);

  return React.useMemo(() => (
    <Modal
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={cancellable}
      closeOnDocumentClick={cancellable}
      closeOnEscape={cancellable}
      primary
      style={{ '--current-color': 'var(--primary)' } as React.CSSProperties}
    >
      <Modal.Header>
        <Icon name="database" />
        {i18n.userComponents}
      </Modal.Header>
      <Modal.Content>
        <ComponentList
          items={items}
          selected={selected}
          onSelect={handleSelect}
        />
      </Modal.Content>
      {!_.isEmpty(items) && (
        <Modal.Actions>
          {cancellable && (
            <Button
              content={i18n.userComponentCancel}
              onClick={handleClose}
              basic
            />
          )}
          <Button
            content={i18n.userComponentSelect}
            disabled={!selected}
            loading={loading}
            labelPosition="right"
            icon="checkmark"
            onClick={handleClick}
            positive={!!selected}
          />
        </Modal.Actions>
      )}
    </Modal>
  ), [
    open,
    items,
    loading,
    selected,
    handleClose,
    handleClick,
    handleSelect,
  ]);
};
