// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import DashboardBarButton from './DashboardBarButton';
import DashboardBarConfirmSupprimer from './DashboardBarConfirmSupprimer';

export const DashboardBarButtonSupprimer = (props) => {
    const { 
        disabled, 
        onClick,
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClick = React.useCallback(() => {
        setLoading(true);
        setOpen(true);
    }, []);

    const handleConfirm = React.useCallback(() => {
        if(onClick) {
            onClick();
        }

        setOpen(false);
        setLoading(false)
    }, [onClick]);

    const handleCancel = React.useCallback(() => {
        setOpen(false);
        setLoading(false);
    }, []);

    const confirmDeleting = React.useMemo(() => (
        <DashboardBarConfirmSupprimer
            header={'Suppression'}
            content={'Êtes-vous sûr de vouloir supprimer ce tableau de bord ?'}
            open={open}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        />
    ), [
        open,
        handleCancel,
        handleConfirm,
    ]);

    return (
        <>
            <DashboardBarButton
                icon='trash'
                label='Supprimer'
                disabled={disabled}
                loading={loading}
                onClick={handleClick}
            />
            {confirmDeleting}
        </>
    );
}

export default DashboardBarButtonSupprimer;
