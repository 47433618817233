// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { Grid } from 'semantic-ui-react';

import DashboardGridCell from '../DashboardGridCell';

const DashboardGridView = (props) => {
    const { 
        widgets,
        onCreate,
        onDelete,
    } = props;
 
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={6}>
                    <DashboardGridCell view={9} position={1}  widget={_.find(widgets, ({ position: 1 }))} onCreate={onCreate} onDelete={onDelete} />
                </Grid.Column>
                <Grid.Column width={10}>
                    <DashboardGridCell view={9} position={2}  widget={_.find(widgets, ({ position: 2 }))} onCreate={onCreate} onDelete={onDelete} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={6}>
                    <DashboardGridCell view={9} position={3}  widget={_.find(widgets, ({ position: 3 }))} onCreate={onCreate} onDelete={onDelete} />
                </Grid.Column>
                <Grid.Column width={10}>
                    <DashboardGridCell view={9} position={4}  widget={_.find(widgets, ({ position: 4 }))} onCreate={onCreate} onDelete={onDelete} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

DashboardGridView.cells = 4;

export default DashboardGridView;
