// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Dropdown, Icon, Modal,
} from 'semantic-ui-react';

import './UserProfile.less';

import * as api from '../../../config/api';
import * as i18n from '../../../config/i18n';
import * as routes from '../../../config/routes';

import { ApplicationSessionContext } from '../../../components/ApplicationSession';

import { TenantSelector } from './Selectors/TenantSelector';
import { ComponentSelector } from './Selectors/ComponentSelector';

import { UserService } from '../services';

import { NavLink } from '../../../services/router';

import { useToggle } from '../utils/useToggle';

import { UserAvatar } from './UserAvatar';

import { privateApi } from '../../../api';

import {
  getApplicationClassName, getClassName,
} from '../../../components/utils/className';

const className = getApplicationClassName('user', 'profile');

export const UserProfile = (props) => {
  const { onOpen } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const [open, handleOpen, handleClose] = useToggle(false);

  const [showTenant, handleShowTenant, handleHideTenant] = useToggle(false);
  const [showComponent, handleShowComponent, handleHideComponent] = useToggle(false);

  const [displayTenant, setDisplayTenant] = React.useState(false);
  const [displayComponent, setDisplayComponent] = React.useState(false);

  const [openAbout, handleOpenAbout, handleCloseAbout] = useToggle(false);

  React.useEffect(() => {
    if (user?.tenant?.identifiant) {
      privateApi.get(api.compilePath(api.tenantEndPoint, {
        tenant: user.tenant.identifiant,
      }))
        .then(({ components } = {}) => {
          if (_.size(components) > 1) {
            setDisplayComponent(true);
          }
        });
    }
  }, [user?.tenant?.identifiant]);

  React.useEffect(() => {
    privateApi.get(api.compilePath(api.tenantsEndPoint))
      .then((results) => {
        if (_.size(results) > 1) {
          setDisplayTenant(true);
        }
      });
  }, [user.identifiant]);

  const handleLogout = React.useCallback(() => {
    UserService.logout(user);
  }, [user]);

  const menu = React.useMemo(() => (
    <Dropdown
      className={className}
      aria-label="user-profile"
      item
      icon={false}
      trigger={<UserAvatar />}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Dropdown.Menu className={getClassName('slide', { in: open, out: !open })}>
        <Dropdown.Item icon="building" text={i18n.userTenants} onClick={handleShowTenant} disabled={!displayTenant} />
        <Dropdown.Item icon="database" text={i18n.userComponents} onClick={handleShowComponent} disabled={!displayComponent} />
        <Dropdown.Divider />
        <Dropdown.Item as={NavLink} icon="settings" text={i18n.userSettings} to={routes.userSettings} exact />
        <Dropdown.Item icon="info circle" text={i18n.userAbout} onClick={handleOpenAbout} />
        <Dropdown.Item icon="log out" text={i18n.authenticationLogout} onClick={handleLogout} />
      </Dropdown.Menu>
    </Dropdown>
  ), [
    className,
    open,
    displayTenant,
    displayComponent,
    handleLogout,
    handleOpen,
    handleClose,
    handleShowTenant,
    handleShowComponent,
    handleOpenAbout,
  ]);

  const tenantModal = React.useMemo(() => (
    <TenantSelector
      show={showTenant}
      onHide={handleHideTenant}
    />
  ), [
    showTenant,
    handleHideTenant,
  ]);

  const componentModal = React.useMemo(() => (
    <ComponentSelector
      show={showComponent}
      onHide={handleHideComponent}
    />
  ), [
    showComponent,
    handleHideComponent,
  ]);

  const aboutModal = React.useMemo(() => (
    <Modal
      className="close-icon-inside"
      open={openAbout}
      onClose={handleCloseAbout}
      closeIcon
      primary
    >
      <Modal.Header>
        <Icon name="info circle" />
        {i18n.userAbout}
      </Modal.Header>
    </Modal>
  ), []);

  React.useEffect(() => {
    if (onOpen && open) {
      onOpen(open);
    }
  }, [open]);

  return React.useMemo(() => (
    <>
      {menu}
      {tenantModal}
      {componentModal}
      {aboutModal}
    </>
  ), [
    menu,
    tenantModal,
    componentModal,
    aboutModal,
  ]);
};
