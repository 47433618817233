// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationLoader } from '../../../components/ApplicationLoader';

export const DomainLauncherListList = (props) => {
  const {
    loading,
    items,
  } = props;

  return loading
    ? <ApplicationLoader />
    : items;
};
