// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { Accordion, Header, Icon } from 'semantic-ui-react';
import * as i18n from '../../../../config/i18n';
import * as api from '../../../../config/api';

import { ApplicationSessionContext } from '../../../../components/ApplicationSession';

import { ApplicationList } from '../../../../components/ApplicationList';
import { ApplicationLoader } from '../../../../components/ApplicationLoader';

import { ComponentListItem } from './ComponentListItem';

import { privateApi } from '../../../../api';

import { Tenant } from '../../Tenant';

export const ComponentList = (props) => {
  const {
    items,
    selected,
    onSelect,
  } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const [components, setComponents] = React.useState(items);
  const [loading, setLoading] = React.useState(_.isEmpty(components));

  React.useEffect(() => {
    privateApi.get(
      api.compilePath(api.tenantEndPoint, { tenant: user?.tenant.identifiant }),
    )
      .then((results: Tenant) => {
        setComponents(results.components);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user?.tenant]);

  const handleClick = React.useCallback((value) => {
    onSelect?.(value);
  }, [onSelect]);

  const empty = React.useMemo(() => (
    <Header icon>
      <Icon className="outline" name="database" />
      {i18n.userComponentNoResult}
    </Header>
  ), []);

  const componentList = React.useMemo(() => (
    <Accordion panels={_.map(_.groupBy(components, 'driver'), (componentsItems, driver) => ({
      key: driver,
      title: {
        content: driver,
      },
      content: {
        content: (
          <ApplicationList placeholder={empty} divided>
            {_.map(componentsItems, (t) => (
              <ComponentListItem
                key={t.identifiant}
                identifiant={t.identifiant}
                nom={t.nom}
                defaut={t.defaut}
                selected={t.identifiant === selected?.identifiant}
                onClick={handleClick}
              />
            ))}
          </ApplicationList>
        ),
      },
    }))}
    />
  ), [
    components,
    selected,
    handleClick,
  ]);

  return React.useMemo(() => (
    loading
      ? <ApplicationLoader />
      : componentList
  ), [
    componentList,
    loading,
  ]);
};
