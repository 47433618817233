// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationLauncherListItem } from '../../../components/ApplicationLauncher';

import { DraftLauncherMenu } from '../../Draft';
import { FavoriteLauncherMenu } from '../../Favorite';
import { ModuleLauncherMenu } from '../../Module';

import { ModuleDataPrefetch } from '../../Module/components/ModuleDataPrefetch';

export const DomainLauncherListItem = (props) => {
  const {
    identifiant,
    libelle,
    icone,
    code,
    adresse,
  } = props;

  return (
    <ModuleDataPrefetch domain={{ identifiant }}>
      <ApplicationLauncherListItem
        libelle={libelle}
        name={`domaine.${_.snakeCase(libelle)}`}
        code={code}
        icon={icone}
        path={adresse}
      >
        <ModuleLauncherMenu key="module" domain={{ identifiant }} />
        <FavoriteLauncherMenu key="favorite" domain={{ identifiant }} />
        <DraftLauncherMenu key="draft" domain={{ identifiant }} />
      </ApplicationLauncherListItem>
    </ModuleDataPrefetch>
  );
};
