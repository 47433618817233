// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Icon, Modal, Button, Label,
} from 'semantic-ui-react';

import { useUpdateEffect } from '@jvs-group/jvs-mairistem-tools';

import './NotificationModal.less';

import * as i18n from '../../../config/i18n';

import { NotificationForm } from './NotificationForm';

import { NotificationService } from '../services';

import { ModuleService } from '../../Module';
import { DomainService } from '../../Domain';

import { getApplicationClassName } from '../../../components/utils/className';

const className = getApplicationClassName('notification', 'modal');

export const NotificationModal = (props) => {
  const {
    trigger,
    notification: defaultNotification,
    onOpen,
    onClose,
  } = props;

  const [notification, setNotification] = React.useState(defaultNotification);
  const [errors, setErrors] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const [module] = ModuleService.queryModule(notification?.module?.identifiant);
  const [domaine] = DomainService.queryDomain(module?.domaine?.identifiant);

  const handleOpen = React.useCallback(() => {
    setOpen(true);

    onOpen?.();
  }, []);

  const handleClose = React.useCallback(() => {
    if (notification?.identifiant) {
      NotificationService.viewNotification(notification?.identifiant);
    }

    setNotification(defaultNotification);
    setErrors([]);
    setEditing(false);
    setValid(false);
    setOpen(false);

    onClose?.();
  }, [
    notification?.identifiant,
    defaultNotification,
  ]);

  const handleChange = React.useCallback((value) => {
    setNotification((n) => ({
      ...n,
      ...value,
    }));
  }, []);

  const handleCancel = React.useCallback(() => {
    setNotification(defaultNotification);
    setErrors([]);
    setEditing(false);
    setValid(false);
    setOpen(false);

    onClose?.();
  }, [defaultNotification]);

  const handleEdit = React.useCallback(() => {
    setEditing(true);
  }, []);

  const handleSave = React.useCallback(() => {
    setLoading(true);

    NotificationService.saveNotification({
      ...notification,
      vu: true,
    })
      .then(() => {
        setNotification(defaultNotification);
        setValid(false);
        setOpen(false);

        onClose?.();
      })
      .finally(() => {
        setErrors([]);
        setEditing(false);
        setLoading(false);
      });
  }, [notification]);

  useUpdateEffect(() => {
    if (editing) {
      const validation = NotificationService.validateNotification(notification);

      setErrors(validation);
      setValid(_.isEmpty(validation));
    }
  }, [
    notification,
    editing,
  ]);

  return (
    <Modal
      className={`${className} close-icon-inside`}
      trigger={trigger}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      size="tiny"
      closeIcon="close inside"
      primary
    >
      <Modal.Header>
        <Icon name="alarm" />
        {_.upperFirst(notification?.type)}
      </Modal.Header>
      <Modal.Content>
        {module && domaine && <Label content={module.libelle} ribbon="right" /> }
        <NotificationForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...notification}
          errors={errors}
          onChange={handleChange}
          onEdit={handleEdit}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={i18n.notificationCancel}
          onClick={handleCancel}
          loading={loading}
          basic
        />
        <Button
          content={i18n.notificationSave}
          onClick={handleSave}
          disabled={!valid}
          loading={loading}
          labelPosition="left"
          icon="checkmark"
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
