// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { Menu } from 'semantic-ui-react';

import DashboardBarButtonPrecedent from './DashboardBarButtonPrecedent';
import DashboardBarButtonSuivant from './DashboardBarButtonSuivant';
import DashboardBarButtonAjouter from './DashboardBarButtonAjouter';
import DashboardBarButtonSupprimer from './DashboardBarButtonSupprimer';

import { DashboardService } from '../services';

import useIndex from '../hooks/useIndex';

export const DashboardBar = () => {
    const [dashboards, loading] = DashboardService.queryDashboards();

    const [current, [currentIndex, maxIndex], [incrementIndex, decrementIndex]] = useIndex(dashboards);

    const handlePrecedent = React.useCallback(() => {
        incrementIndex();
    }, [incrementIndex]);

    const handleSuivant = React.useCallback(() => {
        decrementIndex();
    }, [decrementIndex]);

    const handleAjouter = React.useCallback((dashboard) => {
        DashboardService.addDashboard(dashboard);
    }, []);

    const handleSupprimer = React.useCallback(() => {
        DashboardService.deleteDashboard(current.identifiant);
    }, [current]);

    const actions = React.useMemo(() => (
        <>
            <DashboardBarButtonAjouter onClick={handleAjouter} disabled={loading} />
            <DashboardBarButtonSupprimer onClick={handleSupprimer} disabled={!current} />
        </>
    ), [
        loading,
        current,
        handleAjouter,
        handleSupprimer,
    ]);

    const navigation = React.useMemo(() => (
        <>
            <DashboardBarButtonPrecedent disabled={currentIndex <= 1 || loading} onClick={handlePrecedent} />
            {/* <span>{`${currentIndex}/${maxIndex}`}</span> */}
            <DashboardBarButtonSuivant disabled={currentIndex >= maxIndex || loading} onClick={handleSuivant} />
        </>
    ), [
        loading,
        maxIndex,
        currentIndex,
        handlePrecedent,
        handleSuivant,
    ]);

    return (
        <Menu className="dashboard-bar" secondary fluid icon="labeled">
            {navigation}
            {actions}
        </Menu>
    );
}

export default DashboardBar;
