// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { User } from '../User';
import type { Tenant } from '../Tenant';
import type { Component } from '../Component';
import type { Profile } from '../Profile';

import {
  UserLoginType,
  UserLogoutType,
  UserRefreshType,
  UserComponentType,
  UserProfileType,
  UserTenantType,
} from './types';

export const loginAction = (user: User) => ({
  type: UserLoginType,
  user,
});

export const logoutAction = () => ({
  type: UserLogoutType,
});

export const refreshAction = (user: User) => ({
  type: UserRefreshType,
  user,
  identifiant: user.identifiant,
});

export const tenantAction = (user: User, tenant: Tenant) => ({
  type: UserTenantType,
  user,
  tenant,
});

export const componentAction = (user: User, component: Component) => ({
  type: UserComponentType,
  user,
  component,
});

export const profileAction = (user: User, profile: Profile) => ({
  type: UserProfileType,
  user,
  profile,
});
