// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Favorite } from '../Favorite';

import { FavoritesType, FavoriteType } from './types';

export const addFavoritesAction = (favorites: Favorite[]) => ({
  type: FavoritesType,
  favorites,
});

export const addFavoriteAction = (favorite: Favorite) => ({
  type: FavoriteType,
  favorite,
  identifiant: favorite.identifiant,
});

export const deleteFavoriteAction = (identifiant: string | number) => ({
  type: FavoriteType,
  identifiant,
});
