// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { DragOverlay } from '@dnd-kit/core';

import DashboardWidget from './DashboardWidget';

export const DashboardGridOverlay = (props) => {
    const {
        ...rest
    } = props;

    return ReactDOM.createPortal(
        <DragOverlay>
            <DashboardWidget {...rest} overlay />
        </DragOverlay>,
        document.body
    )
}

export default DashboardGridOverlay;
