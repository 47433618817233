// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { FavoriteLauncherMenuList } from './FavoriteLauncherMenuList';
import { FavoriteLauncherMenuItem } from './FavoriteLauncherMenuItem';

import { FavoriteDomainDataProvider } from './FavoriteDomainDataProvider';

export const FavoriteLauncherMenu = (props) => {
  const { domain } = props;

  const getFavoritesItems = React.useCallback((favoris) => _.map(
    favoris,
    ({
      identifiant, libelle, adresse, ordre,
    }) => (
      <FavoriteLauncherMenuItem
        key={identifiant}
        identifiant={identifiant}
        adresse={adresse}
        libelle={libelle}
        ordre={ordre}
      />
    ),
  ), []);

  const renderFavoritesProvider = React.useCallback((favoris) => (
    <FavoriteLauncherMenuList items={getFavoritesItems(favoris)} />
  ), [getFavoritesItems]);

  return React.useMemo(() => (
    <FavoriteDomainDataProvider domain={domain}>
      {renderFavoritesProvider}
    </FavoriteDomainDataProvider>
  ), [
    domain,
    renderFavoritesProvider,
  ]);
};
