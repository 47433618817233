// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { MenuItem } from './MenuItem';
import { MenuList } from './MenuList';

import { MenuDataProvider } from './MenuDataProvider';

import { levenSearch } from '../../../utils/search';

const minSearch = 1;

export const MenuMenu = (props) => {
  const {
    module,
    search,
  } = props;

  const getMenuItems = React.useCallback((menus, offset = 0, name = '') => _.map(
    _.filter(
      menus,
      ({ libelle, items }) => (
        !(search.length >= minSearch && !_.size(items))
        || levenSearch(libelle, search)
      ),
    ),
    ({
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
      identifiant, code, libelle, icone, adresse, exact, items, module,
    }) => (
      // eslint-disable-next-line no-nested-ternary
      _.size(items)
        ? (
          <MenuList
            key={`${name}.${_.snakeCase(libelle)}-${identifiant}`}
            name={`${name}.${_.snakeCase(libelle)}`}
            identifiant={identifiant}
            libelle={libelle}
            icone={icone}
            adresse={adresse}
            exact={exact}
            module={module}
            offset={offset}
            items={getMenuItems(items, offset + 1, `${name}.${_.snakeCase(libelle)}`)}
          />
        )
        : (code === '__SEPARATOR__'
          ? <hr key={`${name}-seprator-${identifiant}`} />
          : (
            <MenuItem
              key={`${name}.${_.snakeCase(libelle)}-${identifiant}`}
              name={`${name}.${_.snakeCase(libelle)}`}
              identifiant={identifiant}
              libelle={libelle}
              icone={icone}
              adresse={adresse}
              exact={exact}
              module={module}
              offset={offset}
            />
          ))),
  ), [search]);

  const renderMenuProvider = React.useCallback((menus) => (
    <>
      {getMenuItems(menus, 0, 'menu')}
    </>
  ), [getMenuItems]);

  return React.useMemo(() => (
    <MenuDataProvider module={module}>
      {renderMenuProvider}
    </MenuDataProvider>
  ), [
    module,
    renderMenuProvider,
  ]);
};
