// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Grid, Form,
} from 'semantic-ui-react';

import * as api from '../../../config/api';
import * as i18n from '../../../config/i18n';

import {
  HighPriority, LowPriority, NormalPriority,
} from './NotificationIconPriorities';

// import { ApplicationThemeContext } from '../../../components/ApplicationTheme';
import { ApplicationSessionContext } from '../../../components/ApplicationSession';

// import { getClassName } from '../../../components/utils/className';

import { privateApi } from '../../../api';

import toStringDate from '../../utils/toStringDate';

const etats = [
  {
    key: '1',
    text: 'Non commencé',
    value: 1,
  },
  {
    key: '2',
    text: 'En cours de réalisation',
    value: 2,
  },
  {
    key: '3',
    text: 'Terminé',
    value: 3,
  },
];

const priorites = [
  {
    key: '1',
    text: (
      <>
        <LowPriority />
        Faible
      </>
    ),
    value: 1,
  },
  {
    key: '2',
    text: (
      <>
        <NormalPriority />
        Normale
      </>
    ),
    value: 2,
  },
  {
    key: '3',
    text: (
      <>
        <HighPriority />
        Haute
      </>
    ),
    value: 3,
  },
];

export const NotificationForm = (props) => {
  const {
    date,
    echeance,
    priorite,
    etat,
    acheve,
    libelle,
    description,
    type,
    utilisateurs,
    errors,
    onChange,
    onEdit,
  } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const [dateDebut] = toStringDate(date && new Date(date));
  const [dateFin] = toStringDate(echeance && new Date(echeance));

  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const firstRef = React.useRef(null);

  React.useEffect(() => {
    setLoading(true);

    privateApi.get(api.usersEndPoint, { actif: true })
      .then((data: []) => {
        setUsers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    onChange?.({
      [name]: value,
    });

    onEdit?.();
  }, [
    onChange,
    onEdit,
  ]);

  const handleDateChange = React.useCallback(({ target: { name, valueAsDate } }) => {
    onChange?.({
      [name]: valueAsDate,
    });

    onEdit?.();
  }, [
    onChange,
    onEdit,
  ]);

  const handleEtatChange = React.useCallback((event, { value }) => {
    const etatAcheve = Math.min(Math.max(1, value), 3);
    let pourcentAcheve = acheve;

    if (etatAcheve === 1) {
      pourcentAcheve = 0;
    }
    if (etatAcheve === 3) {
      pourcentAcheve = 100;
    }

    onChange?.({
      etat: etatAcheve,
      acheve: pourcentAcheve,
    });

    onEdit?.();
  }, [
    acheve,
    onChange,
    onEdit,
  ]);

  const handleAcheveChange = React.useCallback((event, { value }) => {
    const pourcentAcheve = Math.min(Math.max(0, value), 100);
    let etatAcheve = etat;

    if (pourcentAcheve > 0) {
      etatAcheve = 2;
      if (pourcentAcheve === 100) {
        etatAcheve = 3;
      }
    }

    onChange?.({
      etat: etatAcheve,
      acheve: pourcentAcheve,
    });

    onEdit?.();
  }, [
    etat,
    onChange,
    onEdit,
  ]);

  const handleUtilisateurChange = React.useCallback((event, { value }) => {
    onChange?.({
      utilisateurs: value?.map((identifiant) => _.find(users, { identifiant })),
    });

    onEdit?.();
  }, [
    users,
    onChange,
    onEdit,
  ]);

  const renderLabel = React.useCallback(({ text, icon, utilisateur }) => ({
    icon,
    content: utilisateurs.length > 4 ? `${utilisateur.nom} ${utilisateur.prenom[0]}.` : text,
  }), [utilisateurs]);

  const renderItem = React.useCallback((data) => _.map(data, (utilisateur) => ({
    key: utilisateur.identifiant,
    value: utilisateur.identifiant,
    text: `${utilisateur.nom} ${utilisateur.prenom}`,
    icon: 'user',
    utilisateur,
  })), []);

  const renderValue = React.useCallback((data) => _.map(data, (utilisateur) => (
    utilisateur.identifiant
  )), []);

  React.useEffect(() => {
    onChange?.({
      ...(!dateDebut && { dateDebut: new Date() }),
      ...(!priorite && { priorite: 2 }),
      ...(!etat && { etat: 1 }),
      ...(type === 'tache' && _.isEmpty(utilisateurs) && {
        utilisateurs: [{ identifiant: user.profile?.identifiant }],
      }),
    });
  }, [onChange]);

  React.useLayoutEffect(() => {
    firstRef.current?.focus();
  }, []);

  return (
    <Form>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form.Input
              id="libelle"
              name="libelle"
              label="Libelle"
              placeholder="libelle"
              value={libelle || ''}
              onChange={handleChange}
              error={_.some(errors, (e) => e.id === 'libelle')}
              input={{ ref: firstRef }}
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Form.Input
              type="date"
              name="date"
              label="Date"
              defaultValue={new Date()}
              value={dateDebut || ''}
              onChange={handleDateChange}
              error={_.some(errors, (e) => e.id === 'date')}
            />
          </Grid.Column>
          <Grid.Column width={11}>
            <Form.Select
              name="priorite"
              label="Priorités"
              placeholder="Priorité"
              options={priorites}
              value={priorite || 2}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={handleChange}
              error={_.some(errors, (e) => e.id === 'priorite')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Form.Input
              type="date"
              name="echeance"
              label="Echéance"
              value={dateFin || ''}
              onChange={handleDateChange}
              error={_.some(errors, (e) => e.id === 'echeance')}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Select
              name="etat"
              label="Etat"
              placeholder="Etat"
              options={etats}
              value={etat || 1}
              onChange={handleEtatChange}
              error={_.some(errors, (e) => e.id === 'etat')}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Input
              type="number"
              name="acheve"
              label="% achevé(s)"
              min="0"
              max="100"
              step="25"
              value={acheve || 0}
              onChange={handleAcheveChange}
              error={_.some(errors, (e) => e.id === 'acheve')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form.TextArea
              name="description"
              placeholder="Description..."
              value={description || ''}
              onChange={handleChange}
              error={_.some(errors, (e) => e.id === 'description')}
            />
          </Grid.Column>
        </Grid.Row>
        {type === 'tache' && (
          <Grid.Row>
            <Grid.Column>
              <Form.Dropdown
                id="utilisateurs"
                name="utilisateurs"
                label="Affecter la tâche à"
                placeholder={i18n.userList}
                noResultsMessage={i18n.userEmpty}
                options={renderItem(users)}
                value={renderValue(utilisateurs)}
                onChange={handleUtilisateurChange}
                renderLabel={renderLabel}
                loading={loading}
                error={_.some(errors, (e) => e.id === 'utilisateurs')}
                fluid
                multiple
                deburr
                search
                selection
                required
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form>
  );
};
