// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Icon, Transition,
} from 'semantic-ui-react';

import './NotificationIcon.less';

import { NotificationCount } from './NotificationCount';

import { NotificationService } from '../services';

import {
  getApplicationClassName, getClassName,
} from '../../../components/utils/className';

const className = getApplicationClassName('notification', 'icon');

export const NotificationIcon = (props) => {
  const {
    user,
    open,
  } = props;

  const [visible, setVisible] = React.useState(true);

  const [notifications, loading] = NotificationService.queryUnreadNotifications(user);

  const [count, setCount] = React.useState(notifications.length);

  React.useEffect(() => {
    setCount(notifications.length);
  }, [notifications.length]);

  const counter = React.useMemo(() => (
    <NotificationCount visible={!open} count={count} loading={loading} />
  ), [
    open,
    count,
    loading,
  ]);

  React.useEffect(() => {
    const internal = setInterval(() => {
      if (count && !open) {
        setVisible((v) => !v);
      }
    }, 10000);

    return () => {
      clearInterval(internal);
    };
  }, [
    open,
    count,
  ]);

  return React.useMemo(() => (
    <>
      <Transition visible={visible} animation="tada" duration={500}>
        <Icon className={getClassName(className, { active: count > 0 })} name="alarm" size="large" />
      </Transition>
      {counter}
    </>
  ), [
    counter,
    visible,
  ]);
};
