// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const DashboardsType = 'dashboards';

export const DashboardType = 'dashboard';

export const DashboardCurrentType = 'dashboard.current';

export const DashboardWidgetType = 'dashboard.widget';

export const DashboardWidgetsType = 'dashboard.widgets';
