// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { SpeedDial } from '../SpeedDial';

import { SpeedDialType } from './types';

export const addSpeedDialAction = (speedDial: SpeedDial) => ({
  type: SpeedDialType,
  speedDial,
});
