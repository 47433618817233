// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationListItemAction } from '../../../components/ApplicationList/ApplicationListItemAction';

import { IconOpen } from './ModuleActionIcon';

export const OpenAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;

  return (
    <ApplicationListItemAction
      icon={<IconOpen />}
      title={i18n.navigationOpenTab}
      onClick={onClick}
    />
  );
};
