// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { FavoriteMenuList } from './FavoriteMenuList';
import { FavoriteMenuItem } from './FavoriteMenuItem';

import { FavoriteModuleDataProvider } from './FavoriteModuleDataProvider';

export const FavoriteMenu = (props) => {
  const { module } = props;

  const getFavoritesItems = React.useCallback((favoris) => _.map(
    favoris,
    ({
      identifiant, libelle, adresse, ordre,
    }) => (
      <FavoriteMenuItem
        key={identifiant}
        identifiant={identifiant}
        adresse={adresse}
        libelle={libelle}
        ordre={ordre}
      />
    ),
  ), []);

  const renderFavoritesProvider = React.useCallback((favoris) => (
    <FavoriteMenuList
      key="favorites"
      items={getFavoritesItems(favoris)}
    />
  ), [getFavoritesItems]);

  return React.useMemo(() => (
    <FavoriteModuleDataProvider module={module}>
      {renderFavoritesProvider}
    </FavoriteModuleDataProvider>
  ), [
    module,
    renderFavoritesProvider,
  ]);
};
