// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Icon } from 'semantic-ui-react';

export const IconOpen = () => (
  <Icon name="share square" link />
);
