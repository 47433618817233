// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import * as api from '../../../config/api';

import type { Draft } from '../Draft';
import type { Module } from '../../Module';
import type { Domain } from '../../Domain';

import * as redux from '../redux';

import { privateApi } from '../../../api';

import { useQuery } from '../../libs/hooks/useQuery';
import { Service } from '../../libs/services/Service';

export class DraftService extends Service {
  static async getDrafts(
    parent?: { identifiantDomaine?: string | number, identifiantModule?: string | number },
    args?: Record<string, unknown>,
  ): Promise<Draft[]> {
    try {
      let endPoint = api.compilePath(api.draftsEndPoint);
      let params = api.compileArgs(api.draftsEndPoint, args);

      try {
        endPoint = api.compilePath(api.draftsByDomainEndPoint, parent);
        params = api.compileArgs(api.draftsByDomainEndPoint, args);
      } catch {
        try {
          endPoint = api.compilePath(api.draftsByModuleEndPoint, parent);
          params = api.compileArgs(api.draftsByModuleEndPoint, args);
        } catch {
          endPoint = api.compilePath(api.draftsEndPoint);
          params = api.compileArgs(api.draftsEndPoint, args);
        }
      }

      const result = await privateApi.get<Draft>(
        endPoint,
        params,
      ) as Draft[];

      DraftService.dispatch(redux.addDraftsAction(result));

      return result;
    } catch (e) {
      return [];
    }
  }

  static async deleteDraft(
    identifiant: string | number,
    args?: Record<string, unknown>,
  ): Promise<boolean> {
    try {
      await privateApi.delete<Draft>(
        api.compilePath(api.draftEndPoint, { identifiant }),
        api.compileArgs(api.draftEndPoint, args),
      );

      DraftService.dispatch(redux.deleteDraftAction(identifiant));

      return true;
    } catch (e) {
      return false;
    }
  }

  static queryDraftsByDomain(
    domain: Domain,
  ): [Draft[], boolean, boolean] {
    const drafts = DraftService.select<
    { drafts: Draft[], modules: Module[] }, Draft[]
    >(
      redux.draftsByDomainSelector(domain),
    );

    const [loading, fetching] = useQuery(
      () => DraftService.getDrafts({ identifiantDomaine: domain?.identifiant }),
      [domain?.identifiant],
      _.isEmpty(drafts),
    );

    return [
      drafts,
      loading,
      fetching,
    ];
  }
}
