// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

// import * as FeatureService from '../services';

// import { usePrefetch } from '../../utils/services/hooks/usePrefetch';

export const FeatureDataPrefetch = (props) => {
  const {
    children,
    path,
  } = props;

  // const prefetchFeature = usePrefetch(FeatureService.getFeature);

  const handleHover = React.useCallback(() => {
    // prefetchFeature(path);
  }, [path]);

  return children && React.cloneElement(children, {
    onMouseEnter: handleHover,
  });
};
