// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationListItem } from '../../../../components/ApplicationList';

export const TenantListItem = (props) => {
  const {
    identifiant,
    nom,
    selected,
    onClick,
  } = props;

  const handleClick = React.useCallback(() => {
    onClick?.({
      identifiant,
      nom,
    });
  }, [
    identifiant,
    nom,
    onClick,
  ]);

  return React.useMemo(() => (
    <ApplicationListItem
      content={nom}
      selected={selected}
      onClick={handleClick}
    />
  ), [
    nom,
    selected,
    handleClick,
  ]);
};
