// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { Modal, Segment, Header, Button, Icon, Ref } from 'semantic-ui-react';

import ApplicationThemeContext from '../../../components/ApplicationTheme';

import DashboardWidgetList from './DashboardWidgetList';

import { getClassName } from '../../../components/utils/className';

export const DashboardWidgetPlaceholder = (props) => {
    const { onChange } = props;

    const [open, setOpen] = React.useState(false);
    const [selection, setSelection] = React.useState({ identifiant: null })

    const handleOpen = React.useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = React.useCallback(() => {
        setOpen(false)
    }, [])

    const handleCancel = React.useCallback(() => {
        setOpen(false)
    }, [])

    const handleSave = React.useCallback(() => {
        onChange?.(selection)

        setOpen(false)
    }, [selection])

    const handleSelect = React.useCallback((data) => {
        setSelection(data)
    },[])

    const trigger = React.useMemo(() => (
        <Segment className={getClassName("dashboard-widget", "placeholder")}>
            <Header as='h4' icon textAlign="center" >
                <Header.Content>
                    Ajouter une vue
                </Header.Content>
                <Icon
                    name='share'
                    rotated='clockwise'
                    size='large'
                />
            </Header>
        </Segment>
    ), [])

    const content = React.useMemo(() => (
        <DashboardWidgetList onSelectionChange={handleSelect} />
    ), [handleSelect])

    return (
        <Modal
            className="close-icon-inside"
            trigger={trigger}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            size="tiny"
            closeIcon
        >
            <Modal.Header as={Segment} inverted>
                <Icon name="dashboard" />
                Vue
            </Modal.Header>
            <Modal.Content>
                {content}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={handleCancel}
                    content="Annuler"
                    basic
                />
                <Button
                    onClick={handleSave}
                    content="Selectionner"
                    labelPosition="left"
                    icon="checkmark"
                    disabled={!(selection?.identifiant)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default DashboardWidgetPlaceholder;
