// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { Header, Icon } from 'semantic-ui-react';
import * as i18n from '../../../../config/i18n';
import * as api from '../../../../config/api';

import { ApplicationList } from '../../../../components/ApplicationList';
import { ApplicationLoader } from '../../../../components/ApplicationLoader';

import { TenantListItem } from './TenantListItem';

import { privateApi } from '../../../../api';

import type { Tenant } from '../../Tenant';

export const TenantList = (props) => {
  const {
    items,
    selected,
    onSelect,
  } = props;

  const [tenants, setTenants] = React.useState(items);
  const [loading, setLoading] = React.useState(_.isEmpty(tenants));

  React.useEffect(() => {
    privateApi.get(api.tenantsEndPoint)
      .then((results: Tenant[]) => {
        setTenants(results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClick = React.useCallback((value) => {
    onSelect?.(value);
  }, [onSelect]);

  const empty = React.useMemo(() => (
    <Header icon>
      <Icon className="outline" name="building" />
      {i18n.userTenantNoResult}
    </Header>
  ), []);

  const tenantList = React.useMemo(() => (
    <ApplicationList placeholder={empty} divided>
      {_.map(tenants, (t) => (
        <TenantListItem
          key={t.identifiant}
          identifiant={t.identifiant}
          nom={t.nom}
          selected={t.identifiant === selected?.identifiant}
          onClick={handleClick}
        />
      ))}
    </ApplicationList>
  ), [
    tenants,
    selected,
    handleClick,
  ]);

  return React.useMemo(() => (
    loading
      ? <ApplicationLoader />
      : tenantList
  ), [
    tenantList,
    loading,
  ]);
};
