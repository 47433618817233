// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { ModuleLauncherMenuItem } from './ModuleLauncherMenuItem';
import { ModuleLauncherMenuList } from './ModuleLauncherMenuList';

import { ModuleDataProvider } from './ModuleDataProvider';

export const ModuleLauncherMenu = (props) => {
  const { domain } = props;

  const getModulesItems = React.useCallback((modules) => (
    _.map(modules, ({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      identifiant, code, libelle, adresse, modules, visible,
    }) => (
      <>
        {visible && (
        <ModuleLauncherMenuItem
          key={identifiant}
          identifiant={identifiant}
          code={code}
          libelle={libelle}
          adresse={adresse}
        />
        )}
        {_.map(modules, (mo) => (mo.visible
          && (
          <ModuleLauncherMenuItem
            key={`${identifiant}-${mo.identifiant}`}
            identifiant={mo.identifiant}
            code={mo.code}
            libelle={mo.libelle}
            adresse={mo.adresse}
            module={visible}
          />
          )
        ))}
      </>
    ))
  ), []);

  const renderModulesProvider = React.useCallback((modules) => (
    <ModuleLauncherMenuList items={getModulesItems(modules)} />
  ), [getModulesItems]);

  return React.useMemo(() => (
    <ModuleDataProvider domain={domain}>
      {renderModulesProvider}
    </ModuleDataProvider>
  ), [
    domain,
    renderModulesProvider,
  ]);
};
