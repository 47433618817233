// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { useSortable } from '@dnd-kit/sortable';

import DashboardWidget from './DashboardWidget';
import DashboardWidgetPlaceholder from './DashboardWidgetPlaceholder';

import { getClassName } from '../../../components/utils/className';

export const DashboardGridCell = (props) => {
    const {
        view,
        position,
        widget,
        onCreate,
        onDelete,
    } = props;

    const {
        isDragging,
        isOver,
        listeners,
        setNodeRef,
    } = useSortable({ id: `${view}-${position}` });

    const handleCreateWidget = React.useCallback((widget) => {
        if(onCreate) {
            onCreate({ ...widget, position })
        }
    }, [
        position,
        onCreate,
    ])

    const handleDeleteWidget = React.useCallback((widget) => {
        onDelete?.({ ...widget, position })
    }, [
        position,
        onDelete
    ])

    const content =  React.useMemo(() => (
        <DashboardWidget 
            identifiant={widget?.identifiant} 
            nom={widget?.nom}
            composant={widget?.composant}
            over={isOver}
            dragging={isDragging}
            onDrag={listeners}
            onRemove={handleDeleteWidget}
        />
    ), [
        widget?.identifiant,
        widget?.nom,
        widget?.composant,
        listeners,
        handleDeleteWidget,
    ])

    const placeholder = React.useMemo(() => (
        <DashboardWidgetPlaceholder onChange={handleCreateWidget} />
    ), [handleCreateWidget])

    return (
        <div className={getClassName("dashboard-grid-cell", { 
            dragging: isDragging, over: isOver
        })} ref={setNodeRef}>
            {widget ? content : placeholder}
        </div>
    )
}

export default DashboardGridCell;
