// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import { createSelector } from 'reselect';

import type { Module } from '../../Module';

const getState = (state) => state.speedDials;

const getModule = (speedDials, module) => module;
const getSpeedDialByModule = (speedDials, module) => _.find(speedDials, (d) => _.isEqual(
  _.get(
    d.module,
    'identifiant',
  ),
  module?.identifiant,
));

const selectorSpeedDialByModule = createSelector(
  [getState, getModule],
  getSpeedDialByModule,
);

export const speedDialByModule = (module: Module) => (state) => (
  selectorSpeedDialByModule(state, module)
);
