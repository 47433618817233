// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

export const MenuList = (props) => {
  const {
    name,
    libelle,
    icone,
    adresse,
    exact,
    offset,
    items,
  } = props;

  return (
    <ApplicationMenuItem
      name={name}
      path={adresse}
      content={libelle}
      icon={icone || ''}
      exact={exact}
      offset={offset}
      items={items}
      // disabled={!_.size(items)}
      popup
    />
  );
};
