// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { Dashboard } from '../Dashboard';
import { DashboardService } from '../services';

export const useCurrent = ():
[Dashboard, (dashbord: Dashboard | number) => Promise<boolean>, boolean] => {
  const [dashboard, loading] = DashboardService.queryCurrentDashboard();

  return [
    dashboard,
    DashboardService.setCurrentDashboard,
    loading,
  ];
};

export default useCurrent;
