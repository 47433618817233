import * as React from 'react';

// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import {
    Message, Icon,
} from 'semantic-ui-react';

import * as i18n from '../../config/i18n';

const Default = () => (
    <Message info icon style={{ height: '100%' }}>
        <Icon name="search" />
        <Message.Content>
        <Message.Header>
            {i18n.errorHeader}
        </Message.Header>
        <p>
            <span>{i18n.errorMessage}</span>
        </p>
        </Message.Content>
    </Message>
);

export default Default;
