// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import {
    SwitchTransition, CSSTransition,
  } from 'react-transition-group';

import { Header, Icon } from 'semantic-ui-react';

import { getClassName } from '../../../components/utils/className';

export const DashboardHeader = (props) => {
    const { content } = props;

    const [title, setTitle] = React.useState(content);

    const handleTitleChange = React.useCallback((e) => {
        setTitle(e.target.textContent)
    }, [])

    React.useEffect(() => {
        setTitle(content)
    }, [content])

    return (
        <Header as='h2' className={getClassName('dashboard-header')}>
          <Icon name='dashboard' />
            <SwitchTransition>
                <CSSTransition
                    key={content}
                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                    classNames="fade"
                >
                    <Header.Content style={{ position: 'relative' }}>
                        <div onBlur={handleTitleChange} contentEditable suppressContentEditableWarning={true}>
                            {title}
                        </div>                        
                    </Header.Content>
                </CSSTransition>
            </SwitchTransition>
        </Header>
    )
}

export default DashboardHeader;
