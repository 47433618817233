// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationListItemAction } from '../../../components/ApplicationList/ApplicationListItemAction';

import {
  IconFavorite, IconOpen,
} from './MenuActionIcon';

import { FavoriteService } from '../../Favorite';

export const OpenAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;

  return (
    <ApplicationListItemAction
      icon={<IconOpen />}
      title={i18n.navigationOpenTab}
      onClick={onClick}
    />
  );
};

export const FavoriteAction = (props) => {
  // eslint-disable-next-line react/prop-types
  const { favorite } = props;

  const [loading, setLoading] = React.useState(false);

  const handleAddFavoriteClick = React.useCallback(() => {
    setLoading(true);

    FavoriteService.addFavorite(favorite)
      .finally(() => { setLoading(false); });
  }, [favorite]);

  return (
    <ApplicationListItemAction
      icon={<IconFavorite />}
      title={i18n.favoriteAdd}
      loading={loading}
      onClick={handleAddFavoriteClick}
    />
  );
};
