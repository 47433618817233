// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationLauncherMenuList } from '../../../components/ApplicationLauncher';

export const DraftLauncherMenuList = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    loading,
    // eslint-disable-next-line react/prop-types
    fetching,
    // eslint-disable-next-line react/prop-types
    items,
  } = props;

  return (
    <ApplicationLauncherMenuList
      header={i18n.draftHeader}
      placeholder={i18n.draftEmpty}
      icon="file alternate"
      loading={loading}
      fetching={fetching}
      items={items}
      only="computer tablet"
    />
  );
};
