// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { HistoryMenuList } from './HistoryMenuList';
import { HistoryMenuSection } from './HistoryMenuSection';
import { HistoryMenuItem } from './HistoryMenuItem';

import { HistoryDataProvider } from './HistoryDataProvider';

export const HistoryMenu = (props) => {
  const { module } = props;

  const getHistoryItems = React.useCallback((historys) => _.map(
    historys,
    ({
      identifiant, titre, adresse, etat, date, type, utilisateur,
    }) => (
      <HistoryMenuItem
        key={identifiant}
        titre={titre}
        adresse={adresse}
        etat={etat}
        date={date}
        type={type}
        utilisateur={utilisateur}
      />
    ),
  ), []);

  const getHistoryDates = React.useCallback((historys) => _.map(
    historys,
    (history, date) => (
      <HistoryMenuSection
        name={`history.${_.snakeCase(date)}`}
        date={date}
        items={getHistoryItems(history)}
      />
    ),
  ), [getHistoryItems]);

  const renderHistoryProvider = React.useCallback((history) => (
    <HistoryMenuList
      key="history"
      items={getHistoryDates(history)}
    />
  ), [getHistoryDates]);

  return React.useMemo(() => (
    <HistoryDataProvider module={module}>
      {renderHistoryProvider}
    </HistoryDataProvider>
  ), [
    module,
    renderHistoryProvider,
  ]);
};
