// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { toast } from 'react-toastify';

import {
  Icon, Feed, Container, Button, Menu, Transition,
  Ref,
} from 'semantic-ui-react';

import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import './NotificationListItem.less';

import * as i18n from '../../../config/i18n';

import { ApplicationListItem } from '../../../components/ApplicationList';

import {
  CloseAction, DoneAction, ExpandAction,
} from './NotificationAction';
import {
  HighPriority, LowPriority, NormalPriority,
} from './NotificationIconPriorities';
import {
  AlertType, TaskType,
} from './NotificationIconTypes';
import { NotificationModal } from './NotificationModal';

import { NotificationService } from '../services';

import { getClassName } from '../../../components/utils/className';

const DoneIcon = () => (
  <Icon
    name="check"
    color="green"
  />
);

export const NotificationListItem = (props) => {
  const {
    identifiant,
    adresse,
    libelle,
    description,
    priorite,
    etat,
    date,
    type,
    fait,
    vu,
    module,
    utilisateurs,
  } = props;

  const {
    attributes,
    listeners,
    transform,
    setNodeRef,
  } = useDraggable({ id: identifiant });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const [expanded, setExpanded] = React.useState(false);

  const [loading, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'cancel':
        return false;
      case 'close':
        if (state) {
          NotificationService.deleteNotification(action.identifiant);
          return true;
        }
        return false;
      case 'open':
        return true;
      default:
        return false;
    }
  }, false);

  const handleClose = React.useCallback(() => {
    const toastId = toast.info(
      () => (
        <Menu borderless>
          <Menu.Item content={i18n.notificationDeleting} />
          <Menu.Menu position="right">
            <Button
              primary
              basic
              icon="undo"
              size="mini"
              content={i18n.notificationCancel}
              onClick={() => dispatch({ type: 'cancel' })}
            />
          </Menu.Menu>
        </Menu>
      ),
      {
        style: {
          width: 360, bottom: '2em', left: '-0.5em',
        },
      },
    );

    toast.onChange((payload) => {
      if (payload.id === toastId) {
        switch (payload.status) {
          case 'added':
            dispatch({ type: 'open' });
            break;
          case 'removed':
            dispatch({ type: 'close', identifiant });
            break;
          default:
            break;
        }
      }
    });
  }, [identifiant]);

  const handleDone = React.useCallback(() => {
    NotificationService.doneNotification(identifiant);
  }, [identifiant]);

  const handleIndicate = React.useCallback((event) => {
    NotificationService.viewNotification(identifiant);

    event.preventDefault();
    event.stopPropagation();
  }, [identifiant]);

  const handleExpand = React.useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  const icon = React.useMemo(() => (
    <>
      {type === 'alerte' && <AlertType />}
      {type === 'tache' && <TaskType />}
    </>
  ), [type]);

  const state = React.useMemo(() => (
    <>
      {type === 'alerte' && priorite === 1 && <LowPriority />}
      {type === 'alerte' && priorite === 2 && <NormalPriority />}
      {type === 'alerte' && priorite === 3 && <HighPriority />}
      {type === 'tache' && fait && <DoneIcon />}
    </>
  ), [
    type,
    fait,
    priorite,
  ]);

  const actions = React.useMemo(() => (
    type === 'alerte'
      ? [
        <CloseAction key="close" onClick={handleClose} />,
        <ExpandAction key="expand" expand={expanded} onClick={handleExpand} />,
      ]
      : [
        <CloseAction key="close" onClick={handleClose} />,
        !fait && <DoneAction key="done" onClick={handleDone} />,
      ]
  ), [
    type,
    fait,
    identifiant,
    handleExpand,
    handleClose,
    handleDone,
  ]);

  const content = React.useMemo(() => (
    <Feed className="notification">
      <Feed.Event>
        <Feed.Content>
          <Feed.Date>
            {state}
            {date}
          </Feed.Date>
          <Feed.Summary>
            {icon}
            {libelle}
          </Feed.Summary>
        </Feed.Content>
        <Feed.Extra className={getClassName({ expanded })}>
          {description}
        </Feed.Extra>
        <Feed.Meta className={getClassName({ expanded })}>
          <Transition visible={expanded} animation="fade down">
            <Container textAlign="center">
              <Menu icon="labeled" size="small" compact borderless />
            </Container>
          </Transition>
        </Feed.Meta>
      </Feed.Event>
    </Feed>
  ), [
    date,
    state,
    icon,
    libelle,
    description,
    state,
    expanded,
  ]);

  const trigger = React.useMemo(() => (
    <Ref innerRef={setNodeRef}>
      <ApplicationListItem
        content={content}
        actions={actions}
        path={adresse}
        selected={!vu}
        style={style}
        direction="column"
        removing={Math.abs(transform?.x) > 40}
        onIndicate={handleIndicate}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...listeners}
      />
    </Ref>
  ), [
    identifiant,
    content,
    adresse,
    vu,
    style,
    transform,
    attributes,
    listeners,
    handleIndicate,
  ]);

  return !loading && (
    <NotificationModal
      trigger={trigger}
      notification={{
        identifiant,
        adresse,
        libelle,
        description,
        date,
        type,
        etat,
        priorite,
        vu,
        module,
        utilisateurs,
      }}
    />
  );
};
