// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

export const HistoryMenuSection = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    name,
    // eslint-disable-next-line react/prop-types
    date,
    // eslint-disable-next-line react/prop-types
    items,
  } = props;

  return (
    <ApplicationMenuItem
      name={name}
      content={date}
      icon="empty set"
      items={items}
      offset={1}
      popup
    />
  );
};
