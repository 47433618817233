// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Header, Icon,
} from 'semantic-ui-react';

import * as i18n from '../../../config/i18n';

import { ApplicationList } from '../../../components/ApplicationList';

import { NotificationListItem } from './NotificationListItem';

import { NotificationDataProvider } from './NotificationDataProvider';

export const NotificationList = (props) => {
  const {
    user,
  } = props;

  // const handleRemove = React.useCallback((notification) => {
  //   // eslint-disable-next-line
  //   console.log("remove", notification)
  // }, []);

  const empty = React.useMemo(() => (
    <Header icon>
      <Icon name="alarm" />
      {i18n.notificationEmpty}
    </Header>
  ), []);

  const getNotificationsItems = React.useCallback((notifications) => (
    _.map(notifications, ({
      identifiant, libelle, description, adresse,
      etat, priorite, date, type, fait, vu,
      module, utilisateurs,
    }): JSX.Element => (
      <NotificationListItem
        key={identifiant}
        identifiant={identifiant}
        libelle={libelle}
        description={description}
        adresse={adresse}
        priorite={priorite}
        etat={etat}
        date={date}
        type={type}
        fait={fait}
        vu={vu}
        module={module}
        utilisateurs={utilisateurs}
      />
    ))
  ), []);

  const renderNotificationsProvider = React.useCallback((notifications) => (
    <ApplicationList
      placeholder={empty}
      items={getNotificationsItems(notifications)}
      // onRemove={handleRemove}
      // removable
    />
  ), [getNotificationsItems]);

  return React.useMemo(() => (
    <NotificationDataProvider user={user}>
      {renderNotificationsProvider}
    </NotificationDataProvider>
  ), [
    user,
    renderNotificationsProvider,
  ]);
};
