// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import * as i18n from '../../../config/i18n';

import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

export const HistoryMenuList = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    loading,
    // eslint-disable-next-line react/prop-types
    fetching,
    // eslint-disable-next-line react/prop-types
    items,
  } = props;

  return (
    <ApplicationMenuItem
      name="history"
      icon="history"
      content={i18n.historyHeader}
      items={items}
      disabled={!_.size(items)}
      loading={loading}
      fetching={fetching}
    />
  );
};
