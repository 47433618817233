// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationWindow } from '../../../components/ApplicationWindow';
import { ApplicationLauncherMenuItem } from '../../../components/ApplicationLauncher';

import {
  DeleteAction, OpenAction,
} from './DraftAction';

import { FeatureDataPrefetch } from '../../Feature/components/FeatureDataPrefetch';

export const DraftLauncherMenuItem = (props) => {
  const {
    identifiant,
    adresse,
    titre,
    date,
    description,
  } = props;

  return (
    <ApplicationWindow path={adresse}>
      {({ onOpen }) => (
        <FeatureDataPrefetch path={adresse}>
          <ApplicationLauncherMenuItem
            header={titre}
            content={date}
            description={description}
            actions={[
              <OpenAction key="open" onClick={onOpen} />,
              <DeleteAction key="delete" identifiant={identifiant} />,
            ]}
            path={adresse}
            direction="column"
            popup
            link
          />
        </FeatureDataPrefetch>
      )}
    </ApplicationWindow>
  );
};
