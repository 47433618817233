// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const UserLoginType = 'user.login';
export const UserLogoutType = 'user.logout';
export const UserRefreshType = 'user.refresh';

export const UserTenantType = 'user.tenant';
export const UserComponentType = 'user.component';
export const UserProfileType = 'user.profile';
