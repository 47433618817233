// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import * as api from '../../../config/api';

import type { Favorite } from '../Favorite';
import type { Module } from '../../Module';
import type { Domain } from '../../Domain';

import * as redux from '../redux';

import { privateApi } from '../../../api';

import { useQuery } from '../../libs/hooks/useQuery';
import { Service } from '../../libs/services/Service';

export class FavoriteService extends Service {
  static async getFavorites(
    parent?: { identifiantDomaine?: string | number, identifiantModule?: string | number },
    args?: Record<string, unknown>,
  ): Promise<Favorite[]> {
    try {
      let endPoint = api.compilePath(api.favoritesEndPoint);
      let params = api.compileArgs(api.favoritesEndPoint, args);

      try {
        endPoint = api.compilePath(api.favoritesByDomainEndPoint, parent);
        params = api.compileArgs(api.favoritesByDomainEndPoint, args);
      } catch {
        try {
          endPoint = api.compilePath(api.favoritesByModuleEndPoint, parent);
          params = api.compileArgs(api.favoritesByModuleEndPoint, args);
        } catch {
          endPoint = api.compilePath(api.favoritesEndPoint);
          params = api.compileArgs(api.favoritesEndPoint, args);
        }
      }

      const result = await privateApi.get<Favorite>(
        endPoint,
        params,
      ) as Favorite[];

      FavoriteService.dispatch(redux.addFavoritesAction(result));

      return result;
    } catch (e) {
      return [];
    }
  }

  static async addFavorite(
    favorite: Partial<Favorite>,
    args?: Record<string, unknown>,
  ): Promise<boolean> {
    try {
      const result = await privateApi.post<Favorite>(
        api.compilePath(api.favoritesEndPoint),
        api.compileArgs(api.favoritesEndPoint, { ...favorite, ...args }),
      );

      FavoriteService.dispatch(redux.addFavoriteAction(result));

      return true;
    } catch (e) {
      return false;
    }
  }

  static async deleteFavorite(
    identifiant: string | number,
    args?: Record<string, unknown>,
  ): Promise<boolean> {
    try {
      await privateApi.delete<Favorite>(
        api.compilePath(api.favoriteEndPoint, { identifiant }),
        api.compileArgs(api.favoriteEndPoint, args),
      );

      FavoriteService.dispatch(redux.deleteFavoriteAction(identifiant));

      return true;
    } catch (e) {
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  static async reorderFavorites(
    favorites: Favorite[],
  ): Promise<boolean> {
    try {
      await privateApi.post<Favorite>(
        api.compilePath(api.favoritesReorderEndPoint),
        api.compileArgs(api.favoritesReorderEndPoint, favorites),
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  static existFavorite(
    code: string,
    module: Module,
  ): boolean {
    return FavoriteService.select<{ favorites: Favorite[] }, boolean>(
      redux.favoriteByCodeSelector(code, module),
    );
  }

  static queryFavoritesByDomain(
    domain: Domain,
  ): [Favorite[], boolean, boolean] {
    const favorites = FavoriteService.select<
    { favorites: Favorite[], modules: Module[] }, Favorite[]
    >(
      redux.favoritesByDomainSelector(domain),
    );

    const [loading, fetching] = useQuery(
      () => FavoriteService.getFavorites({ identifiantDomaine: domain?.identifiant }),
      [domain?.identifiant],
      _.isEmpty(favorites),
    );

    return [
      favorites,
      loading,
      fetching,
    ];
  }

  static queryFavoritesByModule(
    module: Module,
  ): [Favorite[], boolean, boolean] {
    const favorites = FavoriteService.select<{ favorites: Favorite[] }, Favorite[]>(
      redux.favoritesByModuleSelector(module),
    );

    const [loading, fetching] = useQuery(
      () => FavoriteService.getFavorites({ identifiantModule: module?.identifiant }),
      [module?.identifiant],
      _.isEmpty(favorites),
    );

    return [
      favorites,
      loading,
      fetching,
    ];
  }
}
