// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Module } from '../Module';

import type { Identified } from '../utils/Identified';
import type { Identifier } from '../utils/Identifier';

export type History = Identified<{
  /** */
  titre: string;

  /** */
  description?: string;

  /** */
  adresse: string;

  /** */
  etat?: number;

  /** */
  module: Identifier<Module>;
}>;

// eslint-disable-next-line no-shadow
export enum HistoryEtat {
  creation = 'creation',
  modification = 'modification',
  suppression = 'suppression',
}
