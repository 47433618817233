// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';
import { UserService } from '../../User';

export const usePrefetch = <T extends (...args: any[]) => any | null>(
  key: string,
  query: T,
): (...args: Parameters<T>) => void => {
  const ref = React.useRef(false);

  const prefetch = (...args: Parameters<T>): void => {
    if (!ref.current) {
      query(...args);
      ref.current = true;
    }
  };

  UserService.addResetable(key, () => { ref.current = false })

  return prefetch;
};
