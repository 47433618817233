// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ApplicationWindow } from '../../../components/ApplicationWindow';
import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

import {
  OpenAction, FavoriteAction,
} from './MenuAction';

import { FavoriteService } from '../../Favorite';
import { FeatureDataPrefetch } from '../../Feature/components/FeatureDataPrefetch';

import { isUrl } from '../../../utils/path';

export const MenuItem = (props) => {
  const {
    name,
    adresse,
    exact,
    libelle,
    icone,
    module,
    offset,
  } = props;

  if (isUrl(adresse)) {
    return (
      <ApplicationMenuItem
        name={`menu.${name}`}
        content={libelle}
        path={adresse}
        icon={icone || 'empty set'}
        offset={offset}
        direction="row"
        target="_blank"
        popup
        link
      />
    );
  }

  return (
    <ApplicationWindow path={adresse}>
      {({ onOpen }) => (
        <FeatureDataPrefetch path={adresse}>
          <ApplicationMenuItem
            name={name}
            content={libelle}
            actions={[
              <OpenAction key="open" onClick={onOpen} />,
              !FavoriteService.existFavorite(name, module) && (
                <FavoriteAction
                  key="favorite"
                  favorite={{
                    code: name, libelle, adresse, module,
                  }}
                />
              ),
            ].filter(Boolean)}
            path={adresse || ''}
            exact={exact}
            icon={icone || ''}
            offset={offset}
            direction="row"
            popup
            link
          />
        </FeatureDataPrefetch>
      )}
    </ApplicationWindow>
  );
};
