// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { useMemo } from '@jvs-group/jvs-mairistem-tools';

import { SpeedDialService } from '../services';

import { DataProvider } from '../../libs/components/DataProvider';

export const SpeedDialDataProvider = (props) => {
  const {
    children,
    module,
  } = props;

  const [speedDial, loading, fetching] = SpeedDialService.querySpeedDialByModule(module);

  return useMemo(
    () => (
      <DataProvider data={speedDial} loading={loading} fetching={fetching}>
        {children}
      </DataProvider>
    ),
    [
      children,
      loading,
      fetching,
      speedDial,
    ],
  );
};
