// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import type { Draft } from '../Draft';

import { DraftsType, DraftType } from './types';

export const addDraftsAction = (drafts: Draft[]) => ({
  type: DraftsType,
  drafts,
});

export const deleteDraftAction = (identifiant: string | number) => ({
  type: DraftType,
  identifiant,
});
