// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { reorder } from '@jvs-group/jvs-mairistem-tools';

import * as i18n from '../../../config/i18n';

import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

import useOrderedFavoris from '../hooks/useOrderedFavoris';

export const FavoriteMenuList = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    loading,
    // eslint-disable-next-line react/prop-types
    fetching,
    // eslint-disable-next-line react/prop-types
    items,
  } = props;

  const [
    orderedItems,
    setOrderedItems,
  ] = useOrderedFavoris(items);

  const handleSort = React.useCallback((oldIndex, newIndex) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setOrderedItems((state) => reorder(state, oldIndex, newIndex));
  }, [setOrderedItems]);

  return (
    <ApplicationMenuItem
      name="favorites"
      icon="favorite"
      content={i18n.favoriteHeader}
      items={orderedItems}
      disabled={!_.size(orderedItems)}
      loading={loading}
      fetching={fetching}
      onSort={handleSort}
      sortable
    />
  );
};
