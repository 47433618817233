// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { ModuleService } from '../services';

import { DraftService } from '../../Draft';
import { FavoriteService } from '../../Favorite';

import { usePrefetch } from '../../libs/hooks/usePrefetch';

export const ModuleDataPrefetch = (props) => {
  const {
    children,
    domain,
  } = props;

  const prefetchDrafts = usePrefetch('drafts', DraftService.getDrafts);
  const prefetchFavorites = usePrefetch('favorites', FavoriteService.getFavorites);

  const handleHover = React.useCallback(() => {
    prefetchDrafts({ identifiantDomaine: domain?.identifiant });
    prefetchFavorites({ identifiantDomaine: domain?.identifiant });

    ModuleService.getModules({ identifiantDomaine: domain?.identifiant });
  }, [domain?.identifiant]);

  return children && React.cloneElement(children, {
    onMouseEnter: handleHover,
  });
};
