// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Ref } from 'semantic-ui-react';

import { ApplicationWindow } from '../../../components/ApplicationWindow';
import { ApplicationMenuItem } from '../../../components/ApplicationMenu';

import {
  DeleteAction, OpenAction, ReorderAction,
} from './FavoriteAction';

import { FeatureDataPrefetch } from '../../Feature/components/FeatureDataPrefetch';

export const FavoriteMenuItem = (props) => {
  const {
    identifiant,
    libelle,
    adresse,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
    active,
  } = useSortable({ id: identifiant });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(active?.id !== identifiant && isSorting && { pointerEvents: 'none' }),
  };

  return (
    <ApplicationWindow path={adresse}>
      {({ onOpen }) => (
        <FeatureDataPrefetch path={adresse}>
          <Ref innerRef={setNodeRef}>
            <ApplicationMenuItem
              content={libelle}
              actions={[
                !isSorting && <OpenAction key="open" onClick={onOpen} />,
                !isSorting && <DeleteAction key="delete" identifiant={identifiant} />,
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ReorderAction key="reorder" {...listeners} />,
              ].filter(Boolean)}
              path={adresse}
              style={style}
              offset={1}
              direction="row"
              popup={!isSorting}
              link={!isSorting}
              sorting={isSorting}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...attributes}
            />
          </Ref>
        </FeatureDataPrefetch>
      )}
    </ApplicationWindow>
  );
};
