// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import { applyMiddleware, combineReducers, compose } from 'redux';

import * as Crypto from 'crypto-js';

import { userReducer } from './User';
import { domainsReducer } from './Domain';
import { draftsReducer } from './Draft';
import { favoritesReducer } from './Favorite';
import { featuresReducer } from './Feature';
import { historyReducer } from './History';
import { menuReducer } from './Menu';
import { modulesReducer } from './Module';
import { notificationsReducer } from './Notification';
import { dashboardsReducer } from './Dashboard';
import { speedDialsReducer } from './SpeedDial';

import { storeManager } from '../services/redux';

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: Map<string, unknown>;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(...enhancer: R[]) => R;

    __modules__: Record<string, { load: () => Promise<unknown> }>;

    // TODO remove legacy modules
    modules: Record<string, { load: () => Promise<unknown> }>
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const reducer = combineReducers({
  user: userReducer,
  domains: domainsReducer,
  modules: modulesReducer,
  features: featuresReducer,
  notifications: notificationsReducer,
  favorites: favoritesReducer,
  drafts: draftsReducer,
  history: historyReducer,
  menu: menuReducer,
  dashboards: dashboardsReducer,
  speedDials: speedDialsReducer,
});

export const enhancer = composeEnhancers(
  applyMiddleware(
    () => (dispatch) => (action) => {
      const key = Crypto.MD5(JSON.stringify(action));

      return new Promise((resolve) => {
        if (!storeManager.isCached(key)) {
          resolve(dispatch(action));
        }

        storeManager.cached(key);
      });
    },
  ),
);
