// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as React from 'react';

import { Button } from 'semantic-ui-react';

import DashboardBarConfirm from './DashboardBarConfirm';

export const DashboardBarConfirmSupprimer = (props) => {
  const {
    header,
    content,
    container,
    open,
    onConfirm,
    onCancel,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const handleCancel = React.useCallback(() => {
    setLoading(true);

    onCancel?.();
  }, [onCancel]);

  const handleConfirm = React.useCallback(() => {
    setLoading(true);

    onConfirm?.();
  }, [onConfirm]);

  React.useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  const actions = React.useMemo(() => (
    <>
      <Button basic size="small" icon="cancel" content="Annuler" disabled={loading} loading={loading} onClick={handleCancel} />
      <Button negative icon="trash" content="Supprimer" disabled={loading} loading={loading} onClick={handleConfirm} />
    </>
  ), [
    loading,
    handleCancel,
    handleConfirm,
  ]);

  return (
    <DashboardBarConfirm
      open={open}
      header={header}
      content={content}
      container={container}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      {actions}
    </DashboardBarConfirm>
  );
};

export default DashboardBarConfirmSupprimer;
