// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { Container, Segment, Menu } from 'semantic-ui-react';

import ApplicationLoader from '../../../components/ApplicationLoader';

import { getClassName } from '../../../components/utils/className';

export const DashboardWidget = (props) => {
    const { 
        identifiant,
        nom,
        composant,
        overlay,
        onDrag,
        onRemove,
    } = props;

    const container = React.useRef<HTMLElement>()

    const id = React.useId()

    const handleClose = React.useCallback(() => {
        onRemove?.(identifiant)
    }, [
        identifiant,
        onRemove,
    ])

    const component = React.useMemo(() => (
        React.createElement(React.lazy(() => import(`./widgets/${composant || 'Default'}`)))
    ), [composant])

    const menu = React.useMemo(() => (
        <Menu secondary>
            <Menu.Item header>{nom}</Menu.Item>
            <Menu.Menu className="actions" position="right">
                <Menu.Item as='a' icon="arrows alternate" {...onDrag} style={{ cursor: 'grab' }} />
                <Menu.Item icon="close" onClick={handleClose} />
            </Menu.Menu>
        </Menu>
    ), [
        nom,
        onDrag,
    ])

    const content = React.useMemo(() => (
        <Container fluid>
            <article ref={container} >
                <React.Suspense fallback={<ApplicationLoader />}>
                    {component}
                </React.Suspense>
            </article>
        </Container>
    ), [component])

    return (
        <Segment 
            className={getClassName("dashboard-widget", { overlay })} 
            raised={overlay}
        > 
            {menu}
            {content}
        </Segment>
    )
}

export default DashboardWidget;
