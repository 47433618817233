// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import { DomainLauncherListItem } from './DomainLauncherListItem';
import { DomainLauncherListList } from './DomainLauncherListList';

import { DomainDataProvider } from './DomainDataProvider';

export const DomainLauncherList = () => {
  const getDomainsItems = React.useCallback((domains) => _.map(
    domains,
    ({
      identifiant, code, libelle, icone, couleur, adresse,
    }) => (
      <DomainLauncherListItem
        key={identifiant}
        identifiant={identifiant}
        code={code}
        libelle={libelle}
        coleur={couleur}
        icone={icone}
        adresse={adresse}
      />
    ),
  ), []);

  const renderDomainsProvider = React.useCallback((domains) => (
    <DomainLauncherListList items={getDomainsItems(domains)} />
  ), [getDomainsItems]);

  return React.useMemo(() => (
    <DomainDataProvider>
      {renderDomainsProvider}
    </DomainDataProvider>
  ), [renderDomainsProvider]);
};
