// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';
import * as React from 'react';

import {
  Modal, Icon, Button,
} from 'semantic-ui-react';

import * as api from '../../../../config/api';
import * as i18n from '../../../../config/i18n';

import type { Tenant } from '../../Tenant';

import { ApplicationSessionContext } from '../../../../components/ApplicationSession';

import { TenantList } from './TenantList';

import { privateApi } from '../../../../api';

import { UserService } from '../../services';

export const TenantSelector = (props) => {
  const {
    show,
    cancellable = true,
    onHide,
    onSelect,
  } = props;

  const { user } = React.useContext(ApplicationSessionContext);

  const [items, setItems] = React.useState([]);
  const [selected, setSelected] = React.useState(user?.tenant);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (user && selected) {
      setLoading(true);

      UserService.tenant(user, selected)
        .then((tenant) => {
          const components = _.find(items, { identifiant: selected.identifiant })?.components;

          UserService.component(user, _.find(components, { defaut: true }))
            .then((component) => {
              UserService.profile(user, tenant, component)
                .then(() => {
                  setLoading(false);

                  onSelect?.(selected);
                  onHide?.();
                })
                .catch(() => { setLoading(false); });
            })
            .catch(() => { setLoading(false); });
        })
        .catch(() => { setLoading(false); });
    }
  }, [
    items,
    user,
    selected,
    onSelect,
    onHide,
  ]);

  const handleSelect = React.useCallback((value) => {
    setSelected(value);
  }, []);

  const handleClose = React.useCallback(() => {
    onHide?.();
  }, [onHide]);

  React.useEffect(() => {
    if (user && show) {
      React.startTransition(() => {
        privateApi.get(api.tenantsEndPoint)
          .then((results: Tenant[]) => {
            const tenants = _.filter(results, (t) => !_.isEmpty(t.components));

            if (_.size(tenants) === 1 && !cancellable) {
              const current = _.first(tenants);

              UserService.tenant(user, current)
                .then((tenant) => {
                  UserService.component(
                    user,
                    _.find(
                      tenant.components,
                      { defaut: true },
                    ),
                  ).then((component) => {
                    UserService.profile(user, tenant, component);
                  });
                });
            } else {
              setItems(tenants);
              setOpen(true);
            }
          });
      });
    } else {
      setOpen(false);
    }
  }, [user?.identifiant, show, cancellable]);

  return React.useMemo(() => (
    <Modal
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={cancellable}
      closeOnDocumentClick={cancellable}
      closeOnEscape={cancellable}
      primary
      style={{ '--current-color': 'var(--primary)' } as React.CSSProperties}
    >
      <Modal.Header>
        <Icon name="building" />
        {i18n.userTenants}
      </Modal.Header>
      <Modal.Content>
        <TenantList
          items={items}
          selected={selected}
          onSelect={handleSelect}
        />
      </Modal.Content>
      {!_.isEmpty(items) && (
        <Modal.Actions>
          {cancellable && (
            <Button
              content={i18n.userTenantCancel}
              onClick={handleClose}
              basic
            />
          )}
          <Button
            content={i18n.userTenantSelect}
            disabled={!selected}
            loading={loading}
            labelPosition="right"
            icon="checkmark"
            onClick={handleClick}
            positive={!!selected}
          />
        </Modal.Actions>
      )}
    </Modal>
  ), [
    open,
    items,
    loading,
    selected,
    handleClose,
    handleClick,
    handleSelect,
  ]);
};
