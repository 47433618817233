// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Icon } from 'semantic-ui-react';

import { reorder } from '@jvs-group/jvs-mairistem-tools';

import * as i18n from '../../../config/i18n';

import { ApplicationLauncherMenuList, ApplicationLauncherMenuAction } from '../../../components/ApplicationLauncher';

import useOrderedFavoris from '../hooks/useOrderedFavoris';

export const FavoriteLauncherMenuList = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    loading,
    // eslint-disable-next-line react/prop-types
    fetching,
    // eslint-disable-next-line react/prop-types
    items,
  } = props;

  const [
    orderedItems,
    setOrderedItems,
  ] = useOrderedFavoris(items);

  const handleSort = React.useCallback((oldIndex, newIndex) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setOrderedItems((state) => reorder(state, oldIndex, newIndex));
  }, [setOrderedItems]);

  const CreateGroupIcon = React.useMemo(() => (
    <Icon.Group>
      <Icon name="object group outline" />
      <Icon name="add" color="green" corner />
    </Icon.Group>
  ), []);

  const CreateGroupAction = React.useMemo(() => (
    <ApplicationLauncherMenuAction
      key="create"
      icon={CreateGroupIcon}
      content={i18n.favoriteGroupAdd}
    />
  ), [CreateGroupIcon]);

  return (
    <ApplicationLauncherMenuList
      header={i18n.favoriteHeader}
      placeholder={i18n.favoriteEmpty}
      icon="favorite"
      loading={loading}
      fetching={fetching}
      items={orderedItems}
      actions={[
        CreateGroupAction,
      ]}
      only="computer"
      onSort={handleSort}
      sortable
    />
  );
};
