// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import { ApplicationList, ApplicationListItem } from '../../../components/ApplicationList';

import { privateApi } from '../../../api';

export const DashboardWidgetList = (props) => {
    const { onSelectionChange } = props;

    const [widgets, setWidgets] = React.useState([])
    const [active, setActive] = React.useState()
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)

        privateApi.get('/api/v1/utilisateurs/dashboards/widgets')
            .then((value: any[]) => {
                setWidgets(value)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])
    
    React.useEffect(() => {
        onSelectionChange?.({ identifiant: active })
    }, [
        active,
        onSelectionChange,
    ])

    const handleClick = React.useCallback((identifiant) => () => (
        setActive(identifiant)
    ), []);

    return (
        <ApplicationList loading={loading} divided>
            {_.map(widgets, (widget) => (
                <ApplicationListItem
                    key={widget.identifiant}
                    content={widget.nom}
                    description={widget.description}
                    onClick={handleClick(widget.identifiant)} 
                    selected={active === widget.identifiant}
                />
            ))}
        </ApplicationList>
    )
}

export default DashboardWidgetList;
