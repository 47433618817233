import { storeManager } from '../../../services/redux';

export class Service {
  protected static dispatch(action) {
    return storeManager.store.dispatch(action);
  }

  protected static select<T, U>(selector: (state: T) => U) {
    return storeManager.select<T, U>(selector);
  }
}
