// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

function getWeekNumber(date: Date) {
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  return Math.ceil((((date.getMilliseconds() - yearStart.getMilliseconds()) / 86400000) + 1) / 7);
}
function parse(str) {
  const [date, time] = str.split(' ');
  return `${date}T${time}.000Z`;
}
const toTextualDate = (date) => {
  const before = new Date(parse(date));
  const now = new Date();

  switch (true) {
    case before.getDate() === now.getDate()
      && before.getMonth() === now.getMonth()
      && before.getFullYear() === now.getFullYear():
      return 'Aujourd\'hui';

    case before.getDate() === now.getDate() - 1
      && before.getMonth() === now.getMonth()
      && before.getFullYear() === now.getFullYear():
      return 'Hier';

    case getWeekNumber(before) === getWeekNumber(now)
      && before.getMonth() === now.getMonth()
      && before.getFullYear() === now.getFullYear():
      return 'Dans la semaine';

    case before.getMonth() === now.getMonth()
      && before.getFullYear() === now.getFullYear():
      return 'Dans le mois';

    case before.getFullYear() === now.getFullYear():
      return 'Dans l\'année';

    default:
      return 'Plus tard';
  }
};

export default toTextualDate;
