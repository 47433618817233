// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as _ from 'lodash';

import { createSelector } from 'reselect';

import type { Module } from '../../Module';

const getState = (state) => state.menu;

const getModule = (menu, module) => module;
const getMenuByModule = (menu, module) => _.filter(menu, (d) => _.isEqual(
  _.get(
    d.module,
    'identifiant',
  ),
  module?.identifiant,
));

const selectorMenuByModule = createSelector(
  [getState, getModule],
  getMenuByModule,
);

export const menuByModuleSelector = (module: Module) => (state) => (
  selectorMenuByModule(state, module)
);
