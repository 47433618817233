// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

/* eslint-disable */

import * as _ from 'lodash';
import * as React from 'react';

import {
    SwitchTransition, CSSTransition,
  } from 'react-transition-group';
  
import { DndContext, pointerWithin, closestCorners } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { SortableContext, rectSwappingStrategy,  } from '@dnd-kit/sortable';

import DashboardGridOverlay from './DashboardGridOverlay';

import { DashboardService } from '../services';

import * as views from './views';

export const DashboardGrid = (props) => {
    const {
        type,
        dashboard,
        widgets,
    } = props;

    const [activeId, setActiveId] = React.useState(null)

    const [items, setItems] = React.useState(widgets)

    const [minimalSize, setMinimalSize] = React.useState({ width: Infinity, height: Infinity })

    const getByPosition = React.useCallback((position) => _.find(items, ({ position })), [items])
    const getPositionById = React.useCallback((id) => parseInt(_.last(_.split(id, '-')), 10), [])
    const getIndexById = React.useCallback((id) => _.findIndex(items, ({ position: getPositionById(id) })), [items])

    const handleDragStart = React.useCallback(({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
    }, [])    

    const handleDragEnd = React.useCallback(({ active, over }) => {        
        if (over) {
            const overPosition = getPositionById(over.id);
            const activePosition = getPositionById(active.id);
            
            if (activePosition !== overPosition) {
                setItems((items) => {
                    const newItems = _.map(items, i => ({
                        ...i,
                        position: i.position === activePosition 
                            ? overPosition 
                            : i.position === overPosition
                            ? activePosition
                            : i.position
                    }))  
                    
                    DashboardService.reorderWidgets(dashboard, [
                        _.find(newItems, { position: activePosition }),
                        _.find(newItems, { position: overPosition })
                    ])

                    return newItems
                });
            }
        }

        setActiveId(null);
    }, [dashboard])

    const handleDragCancel = React.useCallback(() => {
        setActiveId(null);
    }, [])

    const handleCreateWidget = React.useCallback((widget) => {
        DashboardService.addWidget(dashboard, widget)
    }, [dashboard])

    const handleDeleteWidget = React.useCallback((widget) => {
        DashboardService.deleteWidget(dashboard, widget)
    }, [dashboard])

    React.useEffect(() => {
        setItems(widgets)
    }, [widgets])    
    
    const view = views[`DashboardGridView${type}`];

    return view && (
        <div className="dashboard-grid">
            <SwitchTransition>
                <CSSTransition
                    key={type}
                    addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                    classNames="fade"
                >
                    <DndContext 
                        modifiers={[restrictToFirstScrollableAncestor]}
                        collisionDetection={pointerWithin}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}  
                        onDragCancel={handleDragCancel}      
                    >
                        <SortableContext
                            items={_.map(_.range(1, view.cells + 1), (i) => `${type}-${i}`)}
                            strategy={rectSwappingStrategy}
                        >
                            {React.createElement(view, { 
                                widgets: items,
                                onCreate: handleCreateWidget,
                                onDelete: handleDeleteWidget,
                            })}
                        </SortableContext>

                        {activeId && <DashboardGridOverlay size={minimalSize} {...getByPosition(getPositionById(activeId))} />}
                    </DndContext>
                </CSSTransition>
            </SwitchTransition>
        </div>
    )
}

export default DashboardGrid;
